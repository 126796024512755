import { observer } from 'mobx-react-lite';
import { Badge, Tabs, Toggle } from 'tera-dls';

interface IHeaderModalMoreView {
  currentTab: string;
  handleChangeTab: (key: string) => void;
}

const HeaderModalMoreView = ({
  currentTab,
  handleChangeTab,
}: IHeaderModalMoreView) => {
  const classNameBadge = 'px-1 text-red-500 bg-blue-100';
  const tabItems = [
    {
      key: 'all',
      label: (
        <h3 className="tab-table">
          <Badge showZero count={2966} className={classNameBadge}>
            <span>Tất cả</span>
          </Badge>
        </h3>
      ),
    },
    {
      key: 'invest',
      label: (
        <h3 className="tab-table">
          <Badge showZero count={1} className={classNameBadge}>
            <span>Đầu tư</span>
          </Badge>
        </h3>
      ),
    },
    {
      key: 'sale',
      label: (
        <h3 className="tab-table">
          <Badge showZero count={1} className={classNameBadge}>
            <span>Bán hàng</span>
          </Badge>
        </h3>
      ),
    },
    {
      key: 'return',
      label: (
        <h3 className="tab-table">
          <Badge showZero count={29} className={classNameBadge}>
            <span>Trả hàng</span>
          </Badge>
        </h3>
      ),
    },
    {
      key: 'inspection',
      label: (
        <h3 className="tab-table">
          <Badge showZero count={29} className={classNameBadge}>
            <span>Kiểm hàng</span>
          </Badge>
        </h3>
      ),
    },
  ];

  return (
    <div className="flex justify-between border-gray-200 gap-x-5 border-b">
      <Tabs
        onChange={handleChangeTab}
        items={tabItems}
        activeKey={currentTab}
        className="mb-0 border-0"
      />
      <div className="flex items-center gap-2.5 shrink-0">
        <span className="font-medium">Thông báo</span>
        <Toggle containerClassName="block" />
      </div>
    </div>
  );
};

export default observer(HeaderModalMoreView);
