// MODULE SALE
export const PRODUCT_PERMISSION_KEY = {
  VIEW_LIST: 'sale_product_view_product_list',
  VIEW_DETAIL: 'sale_product_view_product_detail',
  SEARCH: 'sale_product_search_product',
  CREATE: 'sale_product_create_product',
  UPDATE: 'sale_product_update_product',
  DELETE: 'sale_product_delete_product',
  CHANGE_STATUS: 'sale_product_change_status_product',
};

export const PRODUCT_CATEGORY_PERMISSION_KEY = {
  VIEW_LIST: 'sale_product_view_category_list',
  VIEW_DETAIL: 'sale_product_view_category_detail',
  SEARCH: 'sale_product_search_category',
  CREATE: 'sale_product_create_category',
  UPDATE: 'sale_product_update_category',
  DELETE: 'sale_product_delete_category',
};

export const PRODUCT_UNIT_PERMISSION_KEY = {
  VIEW_LIST: 'sale_product_view_unit_list',
  VIEW_DETAIL: 'sale_product_view_unit_detail',
  SEARCH: 'sale_product_search_unit',
  CREATE: 'sale_product_create_unit',
  UPDATE: 'sale_product_update_unit',
  DELETE: 'sale_product_delete_unit',
};

export const ORDER_PERMISSION_KEY = {
  VIEW_LIST: 'sale_sale_view_order_list',
  VIEW_DETAIL: 'sale_sale_view_order_detail',
  SEARCH: 'sale_sale_search_order',
  CREATE: 'sale_sale_create_order',
  UPDATE: 'sale_sale_update_order',
  DELETE: 'sale_sale_delete_order',
  CHANGE_STATUS: 'sale_sale_change_status_order',
  PRINT: 'sale_sale_print_order',
};

export const ORDER_INSPECTION_PERMISSION_KEY = {
  VIEW_LIST: 'sale_sale_view_order_inspection_list',
  VIEW_DETAIL: 'sale_sale_view_order_inspection_detail',
  SEARCH: 'sale_sale_search_order_inspection',
  CREATE: 'sale_sale_create_order_inspection',
  UPDATE: 'sale_sale_update_order_inspection',
  DELETE: 'sale_sale_delete_order_inspection',
  APPROVE: 'sale_sale_approve_order_inspection',
  CHECKING: 'sale_sale_checking_order_inspection',
  PRINT: 'sale_sale_print_order_inspection',
};

export const ORDER_RETURN_PERMISSION_KEY = {
  VIEW_LIST: 'sale_sale_view_sale_order_return_list',
  VIEW_DETAIL: 'sale_sale_view_sale_order_return_detail',
  SEARCH: 'sale_sale_search_sale_order_return',
  CREATE: 'sale_sale_create_sale_order_return',
  UPDATE: 'sale_sale_update_sale_order_return',
  DELETE: 'sale_sale_delete_sale_order_return',
  CHANGE_STATUS: 'sale_sale_change_status_sale_order_return',
  PRINT: 'sale_sale_print_sale_order_return',
};

export const SALE_ORDER_PERMISSION_KEY = {
  VIEW_LIST: 'sale_sale_view_sale_order_list',
  VIEW_DETAIL: 'sale_sale_view_sale_order_detail',
  SEARCH: 'sale_sale_search_sale_order',
  PRINT: 'sale_sale_print_sale_order',
};

// MODULE INVESTOR
export const INVESTOR_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_investor_view_investor_list',
  VIEW_DETAIL: 'ivt_investor_view_investor_detail',
  SEARCH: 'ivt_investor_search_investor',
  CREATE: 'ivt_investor_create_investor',
  UPDATE: 'ivt_investor_update_investor',
  DELETE: 'ivt_investor_delete_investor',
  CHANGE_STATUS: 'ivt_investor_change_status_investor',
  CHANGE_PASSWORD: 'ivt_investor_change_password_investor',
};

export const INVESTMENT_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_investment_view_investment_list',
  VIEW_DETAIL: 'ivt_investment_view_investment_detail',
  SEARCH: 'ivt_investment_search_investment',
  CREATE: 'ivt_investment_create_investment',
  UPDATE: 'ivt_investment_update_investment',
  DELETE: 'ivt_investment_delete_investment',
  CHANGE_STATUS: 'ivt_investment_change_status_investment',
  VIEW_CAMERA: 'ivt_investment_history_camera_investment',
};

export const INVESTMENT_TYPE_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_investment_view_investment_type_list',
  VIEW_DETAIL: 'ivt_investment_view_investment_type_detail',
  SEARCH: 'ivt_investment_search_investment_type',
  CREATE: 'ivt_investment_create_investment_type',
  UPDATE: 'ivt_investment_update_investment_type',
  DELETE: 'ivt_investment_delete_investment_type',
  CHANGE_STATUS: 'ivt_investment_change_status_investment_type',
};

export const CEASE_INVESTMENT_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_investment_view_cease_investment_list',
  VIEW_DETAIL: 'ivt_investment_view_cease_investment_detail',
  SEARCH: 'ivt_investment_search_cease_investment',
  CREATE: 'ivt_investment_create_cease_investment',
  UPDATE: 'ivt_investment_update_cease_investment',
  DELETE: 'ivt_investment_delete_cease_investment',
  CHANGE_STATUS: 'ivt_investment_change_status_cease_investment',
};

export const INCURRED_COST_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_cost_view_incurred_cost_list',
  VIEW_DETAIL: 'ivt_cost_view_incurred_cost_detail',
  SEARCH: 'ivt_cost_search_incurred_cost',
  CREATE: 'ivt_cost_create_incurred_cost',
  UPDATE: 'ivt_cost_update_incurred_cost',
  DELETE: 'ivt_cost_delete_incurred_cost',
  CHANGE_STATUS: 'ivt_cost_change_status_incurred_cost',
};

export const SALES_EXPENSES_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_cost_view_sales_expenses_list',
  VIEW_DETAIL: 'ivt_cost_view_sales_expenses_detail',
  SEARCH: 'ivt_cost_search_sales_expenses',
  CREATE: 'ivt_cost_create_sales_expenses',
  UPDATE: 'ivt_cost_update_sales_expenses',
  DELETE: 'ivt_cost_delete_sales_expenses',
};

export const INCURRED_COST_CATEGORY_PERMISSION_KEY = {
  VIEW_LIST: 'ivt_cost_view_incurred_cost_category_list',
  VIEW_DETAIL: 'ivt_cost_view_incurred_cost_category_detail',
  SEARCH: 'ivt_cost_search_incurred_cost_category',
  CREATE: 'ivt_cost_create_incurred_cost_category',
  UPDATE: 'ivt_cost_update_incurred_cost_category',
  DELETE: 'ivt_cost_delete_incurred_cost_category',
  CHANGE_STATUS: 'ivt_cost_change_status_incurred_cost_category',
};

// HR
export const EMPLOYEE_PERMISSION_KEY = {
  VIEW_LIST: 'hr_hr_view_employee_list',
  VIEW_DETAIL: 'hr_hr_view_employee_detail',
  SEARCH: 'hr_hr_search_employee',
  CREATE: 'hr_hr_create_employee',
  UPDATE: 'hr_hr_update_employee',
  DELETE: 'hr_hr_delete_employee',
  CHANGE_PASSWORD: 'hr_hr_change_password_employee',
};

export const TIMEKEEPING_PERMISSION_KEY = {
  VIEW_LIST: 'hr_hr_view_timekeeping_list',
  VIEW_DETAIL: 'hr_hr_view_timekeeping_detail',
  SEARCH: 'hr_hr_search_timekeeping',
  CREATE: 'hr_hr_create_timekeeping',
  UPDATE: 'hr_hr_update_timekeeping',
  DELETE: 'hr_hr_delete_timekeeping',
};

export const DEVICE_PERMISSION_KEY = {
  VIEW_LIST: 'hr_hr_view_device_list',
  VIEW_DETAIL: 'hr_hr_view_device_detail',
  SEARCH: 'hr_hr_search_device',
  CREATE: 'hr_hr_create_device',
  UPDATE: 'hr_hr_update_device',
  DELETE: 'hr_hr_delete_device',
  CHANGE_PASSWORD: 'hr_hr_change_password_device',
};

// MODULE FINANCE
export const INVOICE_PERMISSION_KEY = {
  VIEW_LIST: 'fin_invoice_view_invoice_list',
  VIEW_DETAIL: 'fin_invoice_view_invoice_detail',
  SEARCH: 'fin_invoice_search_invoice',
  CONFIRM: 'fin_invoice_confirm_payment_invoice',
};

export const RECEIPT_PERMISSION_KEY = {
  VIEW_LIST: 'fin_invoice_view_cash_receipt_list',
  VIEW_DETAIL: 'fin_invoice_view_cash_receipt_detail',
  SEARCH: 'fin_invoice_search_cash_receipt',
  CREATE: 'fin_invoice_create_cash_receipt',
  UPDATE: 'fin_invoice_update_cash_receipt',
  DELETE: 'fin_invoice_delete_cash_receipt',
};

export const EXPENSE_VOUCHER_PERMISSION_KEY = {
  VIEW_LIST: 'fin_invoice_view_expense_voucher_list',
  VIEW_DETAIL: 'fin_invoice_view_expense_voucher_detail',
  SEARCH: 'fin_invoice_search_expense_voucher',
  CREATE: 'fin_invoice_create_expense_voucher',
  UPDATE: 'fin_invoice_update_expense_voucher',
  DELETE: 'fin_invoice_delete_expense_voucher',
};

export const STATISTIC_PERMISSION_KEY = {
  STATISTIC_BY_DAY: 'fin_invoice_cash_receipt_expense_voucher_day_statistic',
  STATISTIC_BY_PAYMENT_METHOD:
    'fin_invoice_cash_receipt_expense_voucher_payment_statistic',
  STATISTIC_BY_TYPE: 'fin_invoice_type_cash_receipt_expense_voucher_statistic',
  STATISTIC_BY_INVESTOR:
    'fin_invoice_cash_receipt_expense_voucher_investor_statistic',
};

export const PAYMENT_METHOD_PERMISSION_KEY = {
  VIEW_LIST: 'fin_payment_view_payment_method_list',
  VIEW_DETAIL: 'fin_payment_view_payment_method_detail',
  SEARCH: 'fin_payment_search_payment_method',
  CREATE: 'fin_payment_create_payment_method',
  UPDATE: 'fin_payment_update_payment_method',
  DELETE: 'fin_payment_delete_payment_method',
  QR: 'fin_payment_qr_payment_method',
};

export const TRANSACTION_HISTORY_PERMISSION_KEY = {
  VIEW_LIST: 'fin_payment_view_transaction_history_list',
  VIEW_DETAIL: 'fin_payment_view_transaction_history_detail',
  SEARCH: 'fin_payment_search_transaction_history',
  CHANGE_STATUS: 'fin_payment_change_status_transaction_history',
};

// SYSTEM
export const SYSTEM_PERMISSION_KEY = {
  VIEW_LIST: 'sys_system_view_system_list',
  VIEW_DETAIL: 'sys_system_view_system_detail',
  SEARCH: 'sys_system_search_system',
  CREATE: 'sys_system_create_system',
  UPDATE: 'sys_system_update_system',
  DELETE: 'sys_system_delete_system',
};

export const USER_PERMISSION_KEY = {
  VIEW_LIST: 'sys_system_view_user_list',
  VIEW_DETAIL: 'sys_system_view_user_detail',
  SEARCH: 'sys_system_search_user',
  CREATE: 'sys_system_create_user',
  UPDATE: 'sys_system_update_user',
  DELETE: 'sys_system_delete_user',
};

export const ROLE_PERMISSION_KEY = {
  VIEW_LIST: 'sys_system_view_role_list',
  VIEW_DETAIL: 'sys_system_view_role_detail',
  SEARCH: 'sys_system_search_role',
  CREATE: 'sys_system_create_role',
  UPDATE: 'sys_system_update_role',
  DELETE: 'sys_system_delete_role',
};
