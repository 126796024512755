import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageValidate, messageWarning } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import IncurredCostCategoryApi from '../../api';

interface IncurredCostCategoryFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
}

function IncurredCostCategoryForm(props: IncurredCostCategoryFormProps) {
  const { id, open, onClose } = props;
  const form = useForm({ mode: 'onChange', defaultValues: { status: true } });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-incurred-cost-category-detail', id],
    () => IncurredCostCategoryApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const {
    mutate: mutateUpdateIncurredCostCategory,
    isLoading: isLoadingMutate,
  } = useMutation(
    (params: any) => {
      if (id) return IncurredCostCategoryApi.update({ id, params });
      return IncurredCostCategoryApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-incurred-cost-category-list']);
          queryClient.invalidateQueries([
            'get-summary-incurred-cost-category-list',
          ]);
          onClose();
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value: any) => {
    if ((isLoading && !!id) || isLoadingMutate) return;

    mutateUpdateIncurredCostCategory({
      ...value,
      status: !!value?.status ? 1 : 0,
    });
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        name: dataDetails?.name,
        status: dataDetails?.status === 1 ? true : false,
      };
      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={
        id
          ? 'Sửa danh mục chi phí phát sinh'
          : 'Thêm danh mục chi phí phát sinh'
      }
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Tên danh mục chi phí phát sinh"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem name="status" className="col-span-2">
              <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default IncurredCostCategoryForm;
