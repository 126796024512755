import { useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import DefaultImage from '_common/component/DefaultImage';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  formatDate,
  InformationCircleOutlined,
  ItemType,
  notification,
  Row,
  Spin,
  Tag,
  Tooltip,
} from 'tera-dls';
import OrderApi from '../../api';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { ORDER_URL } from '../../url';

const listImage = [
  {
    id: 1,
    url: 'https://swiperjs.com/demos/images/nature-1.jpg',
  },
  {
    id: 2,
    url: 'https://swiperjs.com/demos/images/nature-2.jpg',
  },
  {
    id: 3,
    url: 'https://swiperjs.com/demos/images/nature-3.jpg',
  },
  {
    id: 4,
    url: 'https://swiperjs.com/demos/images/nature-4.jpg',
  },
  {
    id: 5,
    url: 'https://swiperjs.com/demos/images/nature-5.jpg',
  },
  {
    id: 6,
    url: 'https://swiperjs.com/demos/images/nature-6.jpg',
  },
  {
    id: 7,
    url: 'https://swiperjs.com/demos/images/nature-7.jpg',
  },
  {
    id: 8,
    url: 'https://swiperjs.com/demos/images/nature-8.jpg',
  },
  // {
  //   id: 9,
  //   url: 'https://swiperjs.com/demos/images/nature-9.jpg',
  // },
  // {
  //   id: 10,
  //   url: 'https://swiperjs.com/demos/images/nature-10.jpg',
  // },
];

function RequestOrderDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-order-detail', id], () => OrderApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  // const { mutate, isLoading: loadingChangeStatus } = useMutation(
  //   (params: any) =>
  //     OrderApi.approval({ id: params?.id, params: params?.params }),
  //   {
  //     onSuccess: (res) => {
  //       if (res?.code === 200) {
  //         notification.success({
  //           message: res?.msg,
  //         });
  //         queryClient.invalidateQueries(['get-order-detail']);
  //         queryClient.invalidateQueries(['get-order-list']);
  //         queryClient.invalidateQueries(['get-summary-order-list']);
  //       }
  //     },
  //     onError: (error: any) => {
  //       notification.error({
  //         message: error?.message,
  //       });
  //     },
  //   },
  // );

  const handleReject = () => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn đặt hàng theo yêu cầu',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối đơn đặt hàng theo yêu cầu</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        notification.open({ message: 'Tính năng đang phát triển' });
      },
    });
  };

  const handleApprove = () => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn đặt hàng theo yêu cầu',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối đơn đặt hàng theo yêu cầu</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        notification.open({ message: 'Tính năng đang phát triển' });
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(ORDER_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn đặt hàng
        </a>
      ),
    },
    {
      title: 'Chi tiết đơn đặt hàng theo yêu cầu',
    },
  ];

  const extra = [
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  const detail = [
    {
      label: 'Nhà đầu tư',
      value: dataDetails?.investor_name || 'Hệ thống',
    },
    {
      label: 'Đầu tư',
      value: dataDetails?.invest_id?.name,
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.type],
    },
    {
      label: 'Nhân viên đặt hàng',
      value: dataDetails?.employee_name,
    },
    {
      label: 'Mã đơn hàng',
      value: dataDetails?.code,
    },
    {
      label: 'Ngày duyệt đơn hàng',
      value:
        dataDetails?.approved_date &&
        formatDate(dataDetails?.approved_date, DATE_TIME_FORMAT),
    },
    {
      label: 'Loại phiếu',
      value: TICKET_TYPE[dataDetails?.ticket_type],
    },
    {
      label: 'Trạng thái',
      value: dataDetails?.order_status && (
        <div className="flex gap-x-2.5 items-center">
          <Tag color={ORDER_STATUS_COLOR[dataDetails?.order_status]}>
            {ORDER_STATUS[dataDetails?.order_status]}
          </Tag>
          {dataDetails?.type_status === 'sumi' && (
            <Tooltip
              title={`SUMI ${ORDER_STATUS[dataDetails?.order_status]}`}
              placement="top"
            >
              <div>
                <InformationCircleOutlined className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const request = [
    {
      label: 'Tên sản phẩm',
      value: '__',
    },
    {
      label: 'Số lượng',
      value: '__',
    },
    {
      label: 'Thời gian nhận',
      value: '__',
    },
    {
      label: 'Giá nhận',
      value: '__',
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  console.log('thumbsSwiper', thumbsSwiper);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              type="danger"
              onClick={handleReject}
              disabled={isError || isLoading}
            >
              Từ chối
            </Button>
            <Button
              type="success"
              onClick={handleApprove}
              disabled={isError || isLoading}
            >
              Duyệt
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow grid grid-cols-2 gap-x-2.5 h-[calc(100vh-115px)]">
            <div className="flex flex-col gap-4 overflow-auto">
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin chung">
                  <Row className="grid grid-cols-2 gap-2.5">
                    <div className="flex flex-col gap-y-2.5">
                      {detail.map((item, index) => (
                        <div className="flex items-start" key={index}>
                          <div className="detail-key">{item.label}</div>
                          <div className="detail-value">{item.value}</div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-y-2.5">
                      {extra.map((item, index) => (
                        <div className="flex items-start" key={index}>
                          <div className="detail-key">{item.label}</div>
                          <div className="detail-value">{item.value}</div>
                        </div>
                      ))}
                    </div>
                  </Row>
                </CardFormV2>
              </div>
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin đặt bánh">
                  <Row className="grid grid-cols-2 gap-2.5">
                    {request.map((item, index) => (
                      <div className="flex items-start" key={index}>
                        <div className="detail-key">{item.label}</div>
                        <div className="detail-value">{item.value}</div>
                      </div>
                    ))}
                  </Row>
                </CardFormV2>
                <CardFormV2 title="Ghi chú thông tin khách hàng">
                  <p className="leading-5">Ghi chú thông tin khách hàng</p>
                </CardFormV2>
                <CardFormV2 title="Ghi chú mô tả">
                  <p className="leading-5">Ghi chú mô tả</p>
                </CardFormV2>
              </div>
            </div>
            <div className="h-full flex gap-x-4 overflow-hidden select-none">
              {thumbsSwiper?.mounted && (
                <Swiper
                  spaceBetween={10}
                  navigation={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="sumi-carousel-main rounded-md"
                  thumbs={{ swiper: thumbsSwiper }}
                >
                  {listImage.map((slide) => (
                    <SwiperSlide key={slide.id}>
                      <DefaultImage
                        src={slide.url}
                        alt={slide.url}
                        className="rounded-md cursor-grabbing"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}

              <Swiper
                direction="vertical"
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    setThumbsSwiper(swiper);
                  }, 100);
                }}
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="sumi-carousel-navigation rounded-md"
              >
                {listImage.map((slide) => (
                  <SwiperSlide key={slide.id}>
                    <DefaultImage
                      src={slide.url}
                      alt={slide.url}
                      className="rounded-md cursor-pointer"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default RequestOrderDetail;
