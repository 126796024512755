import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import UnitApi from '../../api';

interface UnitFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function UnitForm(props: UnitFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { isDirty } = form.formState;

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-unit-detail', id], () => UnitApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateUnit, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return UnitApi.update({ id, params });
      return UnitApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-unit-list']);
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    mutateUnit(value);
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        name: dataDetails?.name,
        short_name: dataDetails?.short_name,
      };
      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa đơn vị' : 'Thêm đơn vị'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Tên đơn vị"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Tên viết tắt"
              name="short_name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default UnitForm;
