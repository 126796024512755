import { useQuery } from '@tanstack/react-query';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import UploadFiles from '_common/dof/UploadFiles';
import moment from 'moment';
import { PAYMENT_METHOD_TYPE_TEXT } from 'pages/Finance/constants';
import { renderDescription } from 'pages/Finance/utils';
import { useEffect, useState } from 'react';
import { Checkbox } from 'tera-dls';
import CashReceiptApi from '../../api';
import ExplainTable from '../ExplainTable';

interface IProps {
  id: string | number;
}
const CashExplainContent = (props: IProps) => {
  const { id } = props;
  const [total, setTotal] = useState<number>(0);

  const { data: detail, refetch } = useQuery(
    ['get-cash-receipt-detail', id],
    () => CashReceiptApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    id && refetch();
  }, [id]);

  const data = [
    {
      key: '1',
      title: 'Người nộp',
      value: detail?.author,
    },
    {
      key: '6',
      title: 'Phương thức thanh toán',
      value: PAYMENT_METHOD_TYPE_TEXT[detail?.methods],
    },
    {
      key: '2',
      title: 'Ngày phiếu thu',
      value: detail?.date && moment(detail?.date).format(DATE_TIME_FORMAT),
    },
    // {
    //   key: '3',
    //   title: 'Số điện thoại',
    //   value: submitter?.phone ?? '',
    // },
    {
      key: '4',
      title: 'Ngày hạch toán',
      value:
        detail?.accounting_date &&
        moment(detail?.accounting_date).format(DATE_TIME_FORMAT),
    },
    // {
    //   key: '5',
    //   title: 'Email',
    //   value: submitter?.email ?? '',
    // },
  ];

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-2.5 my-2.5">
        <div className="font-medium text-base text-center break-all">
          Phiếu <span className="lowercase">{detail?.ballot_type_text}</span>
        </div>
        <div className="font-normal text-center break-all">
          Mã phiếu thu: {detail?.code ?? ''}
        </div>
      </div>
      <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
        Thông tin chung
      </div>
      <div className="grid gap-y-2.5 gap-x-5">
        <div className="grid grid-cols-2 gap-y-2.5 gap-x-5">
          {renderDescription(data, 140)}
        </div>
        <div className="grid grid-cols-1 gap-y-2.5 gap-x-5">
          {renderDescription(
            [
              {
                key: 'd1',
                title: 'Ghi chú',
                value: detail?.note ?? '',
              },
            ],
            140,
          )}
        </div>
      </div>
      <div>
        <Checkbox
          checked={Boolean(detail?.is_check)}
          className="pointer-events-none"
          labelClassName="font-normal text-[13px] leading-[16px] pointer-events-none"
        >
          Hạch toán vào kết quả kinh doanh
        </Checkbox>
      </div>
      <div className="flex flex-col gap-2.5">
        <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
          Danh sách hạch toán {total}
        </div>
        <ExplainTable
          type="cash-receipt"
          mode="view"
          id={detail?.id}
          onTotalChange={setTotal}
          objectType="cash-explain"
        />
      </div>
      <div className="flex flex-col gap-2.5">
        <div className="text-blue-500 text-[13px] leading-[15px] inline-block font-medium">
          File đính kèm
        </div>
        <UploadFiles
          mode={'view'}
          isCount={false}
          object_key="cash-receipt"
          folder="cash-receipt"
          fileList={detail?.file_upload?.map((item) => ({
            id: item.id,
            name: item.name,
            url: item.url,
          }))}
          className="max-w-max"
        />
      </div>
    </div>
  );
};

export default CashExplainContent;
