import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import TabForm from '_common/component/TabForm';
import { IconButton } from '_common/component/TableColumnCustom';
import ActionCUD from '_common/component/TableColumnCustom/ActionCUD';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY, REGEX } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import ModalSelectProduct from '_common/dof/Modal/ModalSelectProduct';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import TableTera from '_common/dof/TableTera';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  CubeOutlined,
  InformationCircleOutlined,
  ItemType,
  notification,
  PlusCircleOutlined,
  Row,
  Spin,
  Image as ImageTera,
} from 'tera-dls';
import InvestmentApi from '../../api';
import { INVESTMENT_URL } from '../../url';
import Image from '_common/dof/Control/Image';

const TABS_FORM = {
  information: {
    title: 'Thông tin chung',
    icon: <InformationCircleOutlined className="w-5 h-5" />,
  },
  product: {
    title: 'Sản phẩm',
    icon: <CubeOutlined className="w-5 h-5" />,
  },
};

function InvestmentUpdate() {
  const form = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const tableRef = useRef(null);
  const link_map = form.watch('link_map');

  const [openModalProduct, setOpenModalProduct] = useState({
    open: false,
    params: null,
  });
  const [dataSource, setDataSource] = useState([]);
  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-investment-detail', id],
    () => InvestmentApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateInvestment, isLoading: isLoadingMutate } = useMutation(
    (params: any) => InvestmentApi.update({ id, params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-investment-list']);
          queryClient.invalidateQueries(['get-product-list-investment']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(INVESTMENT_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đầu tư
        </a>
      ),
    },
    {
      title: 'Sửa đầu tư',
    },
  ];

  const columns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => (
        <div className="flex gap-x-2.5">
          <ImageTera
            src={record?.file_upload}
            alt={record?.file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
          />
          <p className="line-clamp-2 break-word">{name}</p>
        </div>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 200,
      editable: true,
      rules: [
        {
          required: messageValidate.emptyText,
          maxLength: {
            value: 15,
            message: 'Nhập tối đa 15 kí tự',
          },
          pattern: {
            value: new RegExp(REGEX.ABBREVIATION),
            message: messageValidate.abbreviation,
          },
        },
      ],
    },
    {
      title: 'Danh mục',
      dataIndex: 'warehouse_cata_log',
      width: 200,
      render: (warehouse_cata_log) => (
        <p className="line-clamp-2">{warehouse_cata_log?.name}</p>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'product_unit_id',
      width: 200,
      render: (product_unit_id) => product_unit_id?.name,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 200,
      editable: true,
      type: 'int',
      inputProps: {
        min: 0,
        max: MAXIMUM_CURRENCY,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
      },
    },
    {
      width: 60,
      render: (record) => (
        <ActionCUD
          onClickDelete={() =>
            setDataSource((prev) => {
              const newData = prev.filter((prod) => prod?.id !== record?.id);
              return newData;
            })
          }
        />
      ),
    },
  ];

  const convertData = (data: any[]) => {
    const dataConverted = data.map((item) => ({
      product_warehouse_id: item?.id,
      abbreviation: item?.abbreviation,
      amount: item?.amount,
      warehouse_catalog_id: item?.warehouse_cata_log?.id,
      warehouse_catalog_name: item?.warehouse_cata_log?.name,
    }));
    return dataConverted;
  };

  const convertDataTableProduct = (data: any[]) => {
    const products = data?.map((prod) => ({
      id: prod?.product_warehouse?.id,
      name: prod?.product_warehouse?.name,
      product_unit_id: prod?.product_warehouse?.product_unit_id,
      abbreviation: prod?.abbreviation,
      amount: prod?.amount,
      warehouse_cata_log: prod?.product_warehouse?.warehouse_cata_log,
      file_upload: prod?.product_warehouse?.file_upload,
    }));
    return products;
  };

  const handleSubmitForm = (value) => {
    const data = {
      ...value,
      products: convertData(dataSource),
    };
    mutateInvestment(data);
  };

  const srcMap = useMemo(() => {
    const srcValue = link_map?.match(/src="([^"]*)"/)?.[1];
    return srcValue;
  }, [link_map]);

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        name: dataDetails?.name,
        code: dataDetails?.code,
        amount: dataDetails?.amount,
        address: dataDetails?.address,
        description: dataDetails?.description,
        link_map: dataDetails?.link_map,
        file_upload: dataDetails?.file_upload,
        type: dataDetails?.type,
      };
      form.reset(initData);

      const products = convertDataTableProduct(dataDetails?.investment);

      setDataSource(products);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Spin spinning={isLoading || isLoadingMutate}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <IconButton
                icon={<ArrowSmallLeftOutlined />}
                callBack={() => navigate(-1)}
              />
              <Breadcrumb separator="/" items={BreadcrumbItem} />
            </div>
            <div className="page-header-v2__function">
              <Button
                className="page-header-v2-btn"
                type="success"
                prefix={<BookmarkOutlined />}
                onClick={form.handleSubmit(handleSubmitForm)}
                disabled={isError}
              >
                Lưu
              </Button>
            </div>
          </div>
        </div>
        <div className="page-content-v2">
          {isError ? (
            <NoData />
          ) : (
            <TabForm menu={TABS_FORM}>
              <FormTera form={form}>
                <CardFormV2
                  title="Thông tin chung"
                  id="information"
                  className="form-section"
                >
                  <Row className="grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0">
                    <FormTeraItem
                      label="Ảnh đại diện"
                      className="flex flex-col col-span-3"
                      name="file_upload"
                    >
                      <Image folder="investment" object_key="investment" />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Mã đầu tư"
                      name="code"
                      rules={[
                        {
                          required: messageValidate.emptyText,
                          pattern: {
                            value: new RegExp(REGEX.CODE),
                            message: messageValidate.code,
                          },
                          maxLength: {
                            value: 100,
                            message: messageValidateLength.text,
                          },
                        },
                      ]}
                    >
                      <Input disabled />
                    </FormTeraItem>

                    <FormTeraItem
                      label="Tên đầu tư"
                      name="name"
                      rules={[
                        {
                          required: messageValidate.emptyText,
                          maxLength: {
                            value: 100,
                            message: messageValidateLength.text,
                          },
                        },
                      ]}
                    >
                      <Input />
                    </FormTeraItem>

                    <FormTeraItem
                      label="Giá bán"
                      name="amount"
                      rules={[
                        {
                          required: messageValidate.emptyText,
                        },
                      ]}
                    >
                      <InputNumber min={0} max={MAXIMUM_CURRENCY} />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Danh mục đầu tư"
                      name="type"
                      rules={[
                        {
                          required: messageValidate.emptySelect,
                        },
                      ]}
                    >
                      <SelectInvestmentCategory />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Địa chỉ cụ thể"
                      name="address"
                      rules={[
                        {
                          maxLength: {
                            value: 255,
                            message: messageValidateLength.textLong,
                          },
                        },
                      ]}
                    >
                      <Input />
                    </FormTeraItem>

                    <FormTeraItem label="Nhúng link Google map" name="link_map">
                      <Input />
                    </FormTeraItem>

                    {srcMap && (
                      <div className="iframe-maps col-span-3 border rounded-md mb-2.5 overflow-hidden">
                        <iframe
                          src={srcMap}
                          width="900"
                          height="400"
                          style={{
                            border: 0,
                          }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    )}

                    <FormTeraItem
                      label="Mô tả"
                      name="description"
                      className="col-span-3"
                      rules={[
                        {
                          maxLength: {
                            value: 1000,
                            message: messageValidateLength.textArea,
                          },
                        },
                      ]}
                    >
                      <TextArea rows={5} />
                    </FormTeraItem>
                  </Row>
                </CardFormV2>
                <CardFormV2
                  title="Sản phẩm"
                  id="product"
                  className="form-section"
                >
                  <Button
                    htmlType="button"
                    className="mb-5 ml-auto"
                    prefix={<PlusCircleOutlined />}
                    onClick={() =>
                      setOpenModalProduct({ open: true, params: null })
                    }
                  >
                    Thêm nhiều sản phẩm
                  </Button>
                  <div className="overflow-hidden bg-white shadow-xsm rounded-[5px]">
                    <TableTera
                      actionRef={tableRef}
                      mode="editable-cell"
                      columns={columns}
                      data={dataSource}
                      pagination={{}}
                      editable={{
                        onValuesChange: (_, list) => {
                          const newList = list?.filter(
                            (item) => !item?.isDelete,
                          );
                          setDataSource(newList);
                        },
                      }}
                    />
                  </div>
                </CardFormV2>
              </FormTera>
            </TabForm>
          )}
        </div>
      </div>
      {openModalProduct.open && (
        <ModalSelectProduct
          paramsApi={{
            exclude_id: dataSource.map((data) => data?.id).join(','),
          }}
          open={openModalProduct.open}
          onCancel={() => setOpenModalProduct({ open: false, params: null })}
          onOk={(selectedRows) =>
            setDataSource((prev) => [...prev, ...selectedRows])
          }
        />
      )}
    </Spin>
  );
}

export default InvestmentUpdate;
