import { useQuery } from '@tanstack/react-query';
import { IPagination } from '_common/interface';
import CashReceiptApi from 'pages/Finance/CashReceipt/api';
import CashReceiptTable from 'pages/Finance/CashReceipt/containers/Table';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaginationProps } from 'tera-dls';

function CashReceipt() {
  const { id } = useParams();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
  });

  const { data: cashReceiptList, isLoading } = useQuery(
    ['get-cash-receipt-invoice-list', id],
    () => {
      const params = {
        invoice_id: id,
        page: pagination?.page || 1,
        limit: pagination?.limit || 10,
      };
      return CashReceiptApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <CashReceiptTable
      loading={isLoading}
      data={cashReceiptList?.data ?? []}
      pagination={{
        onChange: handleChangePage,
        pageSize: cashReceiptList?.per_page,
        current: cashReceiptList?.current_page,
        total: cashReceiptList?.total,
        from: cashReceiptList?.from,
        to: cashReceiptList?.to,
      }}
    />
  );
}

export default CashReceipt;
