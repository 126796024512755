import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { useEffect } from 'react';
import { formatDate, Image, Modal, Spin } from 'tera-dls';
import CategoryApi from '../../api';

interface CategoryDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function CategoryDetail({ id, open, onCancel }: CategoryDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-category-detail', id],
    () => CategoryApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const detail = [
    {
      label: 'Tên danh mục',
      value: dataDetails?.name,
    },
    {
      label: 'Mô tả',
      value: dataDetails?.description,
    },
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Modal
      title="Chi tiết danh mục sản phẩm"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-y-2.5">
            {dataDetails?.file_upload && (
              <Image
                src={dataDetails?.file_upload}
                alt={dataDetails?.file_upload}
                containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden m-auto"
              />
            )}

            {detail.map((item, index) => (
              <div className="flex items-start" key={index}>
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default CategoryDetail;
