import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import TableTera from '_common/dof/TableTera';
import { useEffect, useMemo, useRef, useState } from 'react';

import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import useInfiniteScrollTable from '_common/dof/TableTera/Hooks/useInfiniteScrollTable';
import { ITableRowActionRef } from '_common/dof/TableTera/_interfaces';
import { OPERATION_KEY } from '_common/dof/TableTera/constants';
import _ from 'lodash';
import {
  formatCurrency,
  notification,
  PlusCircleOutlined,
  Spin,
} from 'tera-dls';
import TimeConfigApi from './api';
const objectType = 'time-config';
const limit = 20;
function TimeConfig() {
  const queryClient = useQueryClient();
  const actionRef = useRef<ITableRowActionRef>(null);
  const [dataSource, setDataSource] = useState<any>([]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    // isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['get-time-config-list'],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) =>
      TimeConfigApi.getList({
        params: {
          // sale_cost_id: id,
          page: pageParam ?? 1,
          limit,
        },
      }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.last_page - 1 >= lastPage?.current_page
        ? allPages.length + 1
        : undefined;
    },
  });

  useInfiniteScrollTable({
    objectType,
    callback: () => {
      if (hasNextPage && !isFetching && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    enable: true,
  });

  useEffect(() => {
    queryClient.setQueryData(
      ['sales-expanses-infinite-scroll'],
      (oldData: any) => {
        return {
          pages: [oldData?.pages?.[0] ?? []],
          pageParams: [1],
        };
      },
    );
    refetch();

    return () => {
      queryClient.invalidateQueries(['sales-expanses-infinite-scroll']);
    };
  }, []);

  const { mutateAsync: mutateSave, isLoading: isSaving } = useMutation(
    (variables: any) => {
      if (variables?.type === 'add')
        return TimeConfigApi.create({ params: variables?.params });
      return TimeConfigApi.update({
        id: variables?.id,
        params: variables?.params,
      });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-sales-expenses-detail']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutateAsync: mutateDelete } = useMutation(
    (variables: any) => TimeConfigApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-sales-expenses-detail']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const value = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [data]);

  useEffect(() => {
    if (!value) return;
    setDataSource(value);
  }, [value]);

  // const isDisable = isSaving || isFetching || isFetchingNextPage;
  isSaving;
  const isDisable = false;

  const columns = [
    {
      title: 'Tên chi phí bán hàng',
      dataIndex: 'name',
      width: '50%',
      editable: true,
      rules: [{ required: messageValidate.emptyText }],
      inputProps: {
        autoFocus: true,
        maxLength: 255,
      },
      render: (val) => <span className="break-word">{val}</span>,
    },
    {
      title: 'Số tiền (đ)',
      dataIndex: 'price',
      width: '45%',
      type: 'int',
      editable: true,
      rules: [{ required: messageValidate.emptyText }],
      inputProps: {
        min: 0,
        max: MAXIMUM_CURRENCY,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      render: (val) => formatCurrency(val),
    },
    {
      title: (
        <div className="flex justify-center">
          {isDisable ? (
            <div className="flex justify-center">
              <Spin
                spinning
                svgProps={{ className: 'w-4 h-[20px]' }}
                wrapperClassName=" h-[10px]"
              />
            </div>
          ) : (
            <PlusCircleOutlined
              className={`w-5 cursor-pointer text-green-500 table-row-except-cope ${
                isDisable ? '!text-gray-500' : ''
              }`}
              onClick={() => {
                !isDisable && actionRef.current?.addRow();
              }}
            />
          )}
        </div>
      ),
      dataIndex: OPERATION_KEY,
      width: 60,
    },
  ];

  const handleAdd = async () => {
    try {
      const response = await mutateSave({
        type: 'add',
        params: {},
      });
      if (response?.code !== 200) return;
      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pageParams: [oldData?.pageParams?.[0]],
          };
        },
      );
      refetch({
        refetchPage: (page, index) => {
          return index === 0;
        },
      });
    } catch (e) {}
  };

  const handleDelete = async (record, index) => {
    try {
      const response = await mutateDelete({ id: record.id });
      const currentPage = Math.floor(index / limit) + 1;
      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;

      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = async (record) => {
    try {
      const response = await mutateSave({
        type: 'update',
        id: record?.id,
        params: {
          sale_cost_id: record?.sale_cost_id,
          price: record?.price,
          name: record?.name,
        },
      });
      const currentPage = Math.floor(record?.index / limit) + 1;

      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;
      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {}
  };

  return (
    <div className="tera-page">
      <HeaderViewList title="Danh sách quyền">
        <TableTera
          className="rounded overflow-hidden"
          objectType={objectType}
          // loading={isLoading}
          loadingIndicator={{
            loading: isFetchingNextPage,
          }}
          recordCreatorProps={{
            record: () => ({ price: 0 }),
          }}
          scroll={{ y: 360 }}
          data={dataSource}
          columns={columns}
          rowKey={'id'}
          mode="editable-row"
          actionRef={actionRef}
          editable={{
            onAdd: handleAdd,
            onDelete: handleDelete,
            onUpdate: handleUpdate,
          }}
        />
      </HeaderViewList>
    </div>
  );
}

export default TimeConfig;
