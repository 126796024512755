import { useStores } from './useStores';

export function usePermission() {
  const {
    authStore: { role, permissions, modules, epics },
  } = useStores();

  const checkRole = () => {
    return true;
  };

  const checkModule = () => {
    return true;
  };

  const hasModule = (epic_key: string) => {
    if (role === 'admin') return true;
    if (epics.indexOf(epic_key) > -1) return true;

    return false;
  };

  const hasPage = (page_key: string) => {
    // if (role === 'admin') return true;
    // if (permissions.indexOf(page_key) > -1) return true;
    page_key;
    return true;
  };

  const hasButton = (button_key: string) => {
    if (role === 'admin') return true;
    if (permissions.indexOf(button_key) > -1) return true;

    return false;
  };

  return {
    permissions,
    modules,
    role,
    hasModule,
    hasPage,
    checkRole,
    checkModule,
    hasButton,
  };
}
