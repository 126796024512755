import NoData from '_common/component/NoData';
import { IconButton } from '_common/component/TableColumnCustom';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  ItemType,
  Row,
  Spin,
  Tabs,
} from 'tera-dls';
import { PERMISSION_URL } from '../../url';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import classNames from 'classnames';
import { REGEX, SUMI_SHADOW } from '_common/constants/common';
import Input from '_common/dof/Control/Input';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';

function PermissionForm() {
  const navigate = useNavigate();
  const form = useForm();
  const { id } = useParams();

  const headingClassName =
    'text-blue-500 font-medium text-base border-b py-4 px-2.5';

  const handleSubmitForm = (values) => console.log(values);

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(PERMISSION_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách quyền
        </a>
      ),
    },
    {
      title: id ? 'Sửa cấu hình quyền' : 'Thêm cấu hình quyền',
    },
  ];

  const data = {
    investor: {
      investor: [
        {
          title: 'Xem danh sách nhà đầu tư',
          key: 'ivt_investor_view_investor_list',
        },
        {
          title: 'Bộ lọc và tìm kiếm nhà đầu tư',
          key: 'ivt_investor_search_investor',
        },
      ],
      invest: [
        {
          title: 'Xem danh sách đầu tư',
          key: 'ivt_investment_view_investment_list',
        },
        {
          title: 'Bộ lọc và tìm kiếm Đầu tư',
          key: 'ivt_investment_search_investment',
        },
      ],
    },
    sale: {
      product: [
        {
          title: 'Xem danh sách sản phẩm',
          key: 'sale_product_view_product_list',
        },
      ],
      order: [
        {
          title: 'Xem danh sách đơn đặt hàng',
          key: 'sale_sale_view_order_list',
        },
      ],
    },
  };
  data;
  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              // disabled={isError || (isLoading && !!id) || isLoadingMutate}
            >
              {'Lưu'}
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={false}>
        <div className="page-content-v2">
          {false ? (
            <NoData />
          ) : (
            <FormTera
              form={form}
              className={classNames('p-4 rounded bg-white', SUMI_SHADOW)}
            >
              <Row className="grid-cols-2">
                <FormTeraItem
                  label="Mã quyền"
                  name="code"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.CODE),
                        message: messageValidate.code,
                      },
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
                <FormTeraItem
                  label="Tên quyền"
                  name="name"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      maxLength: {
                        value: 100,
                        message: messageValidateLength.text,
                      },
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
                <Col className="col-span-2 rounded overflow-hidden border">
                  <Row className="grid-cols-4 gap-0">
                    <Col className={classNames(headingClassName, 'col-span-1')}>
                      Danh sách module
                    </Col>
                    <Col
                      className={classNames(
                        headingClassName,
                        'col-span-3 border-l',
                      )}
                    >
                      Danh sách màn hình và chức năng
                    </Col>
                    <Col className="col-span-1 bg-[#F3F4F6]">
                      <Tabs
                        activeClassName="bg-white"
                        className="sumi-system-permission__tab-container border-r-0"
                        itemClassName="sumi-system-permission__tab-item border-b"
                        tabPosition="left"
                        items={[
                          {
                            key: '1',
                            label: 'Chức năng 1',
                          },
                          {
                            key: '2',
                            label: 'Chức năng 2',
                          },
                          {
                            key: '3',
                            label: 'Chức năng 2',
                          },
                        ]}
                      />
                    </Col>
                    <Col className="col-span-3 py-2.5 px-4">
                      <Checkbox>Cho phép tất cả quyền</Checkbox>
                      <Tabs
                        items={[
                          {
                            key: 'investor',
                            label: 'Nhà đầu tư',
                          },
                          {
                            key: 'invest',
                            label: 'Đầu tư',
                          },
                        ]}
                      />
                      <Checkbox onChange={() => console.log()}>Tất cả</Checkbox>
                      <Row className="pl-10 grid-cols-6 mt-2.5">
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                        <Checkbox>Quyền 1</Checkbox>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormTera>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default PermissionForm;
