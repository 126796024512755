import { SUMI_SHADOW } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import classNames from 'classnames';
import { formatDate, Image } from 'tera-dls';

function Item({ dataDetails }) {
  const detail = [
    {
      label: 'Nhà đầu tư',
      value: dataDetails?.invest?.name,
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.invest?.type],
    },
    {
      label: 'Đầu tư',
      value: dataDetails?.invest?.name,
    },
    {
      label: 'Tên thiết bị đánh giá đánh giá',
      value: dataDetails?.invest?.device?.full_name,
    },
    {
      label: 'Ngày đánh giá - góp ý',
      value: formatDate(dataDetails?.created_at),
    },
  ];
  return (
    <div className={classNames('px-2.5 py-4 bg-white rounded-md', SUMI_SHADOW)}>
      <Row className="grid grid-cols-3 gap-2.5">
        {detail.map((item, index) => (
          <div className="flex items-start gap-x-2.5" key={index}>
            <div className="detail-key !min-w-[200px]">{item.label}</div>
            <div className="detail-value">{item.value}</div>
          </div>
        ))}
      </Row>
      <div className="flex flex-col gap-y-2.5 mt-2.5">
        <div className="border border-gray-200 rounded-md p-2.5 flex flex-col gap-y-2.5">
          {dataDetails?.feedback_image.length > 0 && (
            <div className="flex gap-2.5">
              <p className="w-[180px] text-[#6B7280]">Hình ảnh chứng minh</p>
              <div className="flex-1 flex flex-wrap gap-2.5">
                {dataDetails?.feedback_image?.map((image) => (
                  <Image
                    src={image}
                    alt={image}
                    containerClassName="w-[100px] h-[100px] rounded overflow-hidden border"
                  />
                ))}
              </div>
            </div>
          )}
          <div className="flex gap-2.5">
            <p className="w-[180px] text-[#6B7280]">Mô tả</p>
            <p className="flex-1 break-word">{dataDetails?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Item;
