import CardFormV2 from '_common/component/CardForm/CardFormV2';
import {
  DATE_TIME_FORMAT,
  SUMI_SHADOW,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import classNames from 'classnames';
import { formatCurrency, formatDate, formatNumber, Image, Tag } from 'tera-dls';

function Information({ dataDetails }) {
  const detail = [
    {
      label: 'Ảnh đại diện',
      value: dataDetails?.file_upload && (
        <Image
          src={dataDetails?.file_upload}
          alt={dataDetails?.file_upload}
          containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden"
        />
      ),
      className: 'col-span-3',
    },
    {
      label: 'Tên sản phẩm',
      value: dataDetails?.name,
    },
    {
      label: 'Giới hạn số lượng đặt',
      value: formatNumber(dataDetails?.quantity),
    },
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Tên viết tắt',
      value: dataDetails?.abbreviation,
    },
    {
      label: 'Đơn vị',
      value: dataDetails?.product_unit_id?.name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Mã vạch/Barcode',
      value: '__',
    },
    {
      label: 'Giá bán',
      value: dataDetails?.amount && formatCurrency(dataDetails?.amount),
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: 'Danh mục',
      value: dataDetails?.warehouse_cata_log?.name,
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  console.log(dataDetails);

  return (
    <div className="flex flex-col gap-y-2.5">
      <div
        className={classNames(
          'px-2.5 py-5 rounded-md border border-blue-400',
          SUMI_SHADOW,
        )}
      >
        <CardFormV2 title="Thông tin chung">
          <Row className="grid grid-cols-3 gap-2.5">
            {detail.map((item, index) => (
              <div
                className={classNames('flex items-start', item.className)}
                key={index}
              >
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </Row>
        </CardFormV2>
      </div>
      {dataDetails?.files_upload?.length > 0 && (
        <div
          className={classNames(
            'px-2.5 py-5 rounded-md border border-blue-400',
            SUMI_SHADOW,
          )}
        >
          <CardFormV2 title="Hình ảnh">
            <div className="flex gap-x-5 flex-wrap">
              {dataDetails?.files_upload?.map((file) => (
                <Image
                  containerClassName="w-[100px] h-[100px] rounded overflow-hidden border bg-gray-100"
                  src={file?.file_path}
                  alt={file?.file_name}
                />
              ))}
            </div>
          </CardFormV2>
        </div>
      )}
    </div>
  );
}

export default Information;
