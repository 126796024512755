import React from 'react';

function Menu2(props) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.16667 1C1.16667 0.723858 1.39052 0.5 1.66667 0.5H15C15.2761 0.5 15.5 0.723858 15.5 1C15.5 1.27614 15.2761 1.5 15 1.5H14.8333V16.5H15.6667C15.9428 16.5 16.1667 16.7239 16.1667 17C16.1667 17.2761 15.9428 17.5 15.6667 17.5H1C0.723858 17.5 0.5 17.2761 0.5 17C0.5 16.7239 0.723858 16.5 1 16.5H1.83333V1.5H1.66667C1.39052 1.5 1.16667 1.27614 1.16667 1ZM2.83333 1.5V16.5H5.16667V14C5.16667 13.1716 5.83824 12.5 6.66667 12.5H10C10.8284 12.5 11.5 13.1716 11.5 14V16.5H13.8333V1.5H2.83333ZM10.5 16.5V14C10.5 13.7239 10.2761 13.5 10 13.5H6.66667C6.39052 13.5 6.16667 13.7239 6.16667 14V16.5H10.5ZM5.16667 4.33333C5.16667 4.05719 5.39052 3.83333 5.66667 3.83333H7C7.27614 3.83333 7.5 4.05719 7.5 4.33333C7.5 4.60948 7.27614 4.83333 7 4.83333H5.66667C5.39052 4.83333 5.16667 4.60948 5.16667 4.33333ZM9.16667 4.33333C9.16667 4.05719 9.39052 3.83333 9.66667 3.83333H11C11.2761 3.83333 11.5 4.05719 11.5 4.33333C11.5 4.60948 11.2761 4.83333 11 4.83333H9.66667C9.39052 4.83333 9.16667 4.60948 9.16667 4.33333ZM5.16667 7C5.16667 6.72386 5.39052 6.5 5.66667 6.5H7C7.27614 6.5 7.5 6.72386 7.5 7C7.5 7.27614 7.27614 7.5 7 7.5H5.66667C5.39052 7.5 5.16667 7.27614 5.16667 7ZM9.16667 7C9.16667 6.72386 9.39052 6.5 9.66667 6.5H11C11.2761 6.5 11.5 6.72386 11.5 7C11.5 7.27614 11.2761 7.5 11 7.5H9.66667C9.39052 7.5 9.16667 7.27614 9.16667 7ZM5.16667 9.66667C5.16667 9.39052 5.39052 9.16667 5.66667 9.16667H7C7.27614 9.16667 7.5 9.39052 7.5 9.66667C7.5 9.94281 7.27614 10.1667 7 10.1667H5.66667C5.39052 10.1667 5.16667 9.94281 5.16667 9.66667ZM9.16667 9.66667C9.16667 9.39052 9.39052 9.16667 9.66667 9.16667H11C11.2761 9.16667 11.5 9.39052 11.5 9.66667C11.5 9.94281 11.2761 10.1667 11 10.1667H9.66667C9.39052 10.1667 9.16667 9.94281 9.16667 9.66667Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu2;
