import { useMutation, useQueryClient } from '@tanstack/react-query';
import ModalChangePassword from '_common/component/ModalChangePassword';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  formatNumber,
  Image,
  notification,
  Tag,
} from 'tera-dls';
import InvestmentApi from '../../api';
import { INVESTMENT_URL } from '../../url';
import InvestmentForm from '../Form';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { usePermission } from '_common/hooks/usePermission';
import { INVESTMENT_PERMISSION_KEY } from '_common/constants/permission';

function InvestmentTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openResetPassword, setOpenResetPassword] = useState({
    open: false,
    id: null,
  });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => InvestmentApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investment-list']);
          queryClient.invalidateQueries(['get-summary-investment-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => InvestmentApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investment-list']);
          queryClient.invalidateQueries(['get-summary-investment-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangeStatus = (record) => {
    const text = record?.status == 1 ? 'ngưng' : 'mở';
    confirm.warning({
      title: `Xác nhận ${text} hoạt động đầu tư`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text} hoạt động đầu tư</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: record?.id,
          status: record?.status == 1 ? 0 : 1,
        };
        mutateChangeStatus(params);
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đầu tư</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(INVESTMENT_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id && navigate(`${INVESTMENT_URL.detail.path}/${record?.id}`),
      });
    if (hasPage(INVESTMENT_PERMISSION_KEY.VIEW_CAMERA))
      items.push({
        key: '2',
        label: 'Lịch sử camera',
      });
    if (
      hasPage(INVESTMENT_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.investor_id === 0
    )
      items.push({
        key: '3',
        label: record?.status == 1 ? 'Ngưng hoạt động' : 'Hoạt động',
        onClick: () => handleChangeStatus(record),
      });
    if (
      hasPage(INVESTMENT_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.investor_id === 0
    )
      items.push({
        key: '4',
        label: 'Sửa',
        onClick: () => navigate(`${INVESTMENT_URL.update.path}/${record?.id}`),
      });
    if (hasPage(INVESTMENT_PERMISSION_KEY.DELETE) && !record?.status)
      items.push({
        key: '5',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đầu tư',
      dataIndex: 'code',
      width: 100,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'file_upload',
      width: 80,
      render: (file_upload) =>
        file_upload && (
          <Image
            src={file_upload}
            alt={file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border"
          />
        ),
    },
    {
      title: 'Tên đầu tư',
      dataIndex: 'name',
      width: 200,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_id',
      width: 200,
      render: (investor_id, record) => (
        <p className="line-clamp-2">
          {investor_id ? record?.investor?.investor_name : 'Hệ thống'}
        </p>
      ),
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 150,
      render: (type) => INVESTMENT_CATEGORY[type],
    },
    {
      title: 'Địa điểm',
      dataIndex: 'address',
      width: 200,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Giá bán',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'sum_quantity_request',
      width: 150,
      render: (amount) => formatNumber(amount?.total_quantity_request),
    },
    {
      title: 'Số lượng bán',
      dataIndex: 'sum_quantity_sold',
      width: 150,
      render: (amount) => formatNumber(amount?.total_quantity_sold),
    },
    {
      title: 'Tổng doanh thu',
      dataIndex: 'total_revenue',
      width: 150,
      render: (amount) => formatCurrency(amount),
    },
    {
      title: 'Ngày mua',
      dataIndex: 'invoice',
      width: 150,
      render: (date) => formatDate(date?.updated_at),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) =>
        status && (
          <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
            {TAB_ITEMS_STATUS[status]}
          </Tag>
        ),
    },
    {
      width: 60,
      dataIndex: 'action',
      fixed: 'right',
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || loadingChangeStatus || loadingDelete}
        {...props}
      />
      {openForm.open && (
        <InvestmentForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
      {openResetPassword.open && (
        <ModalChangePassword
          userId={openResetPassword?.id}
          open={openResetPassword.open}
          onClose={() => setOpenResetPassword({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default InvestmentTable;
