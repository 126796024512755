import RangeDatePicker from '_common/dof/Control/RangeDatePicker';
import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { Modal } from 'tera-dls';
import HeaderModalMoreView from './Header';
import { useState } from 'react';
import Item from './Item';

function ModalNotification({ open, onCancel }) {
  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = (key) => {
    setCurrentTab(key);
  };

  return (
    <Modal
      title="Tất cả thông báo"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="xl:w-[1200px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <FormTera>
        <FormTeraItem name="keyword">
          <Search className="rounded !w-full" />
        </FormTeraItem>
        <RangeDatePicker
          endFormName="from_date"
          startFormName="to_date"
          endFormItemProps={{
            label: 'Từ ngày',
          }}
          startFormItemProps={{
            label: 'Đến ngày',
          }}
        />
        <HeaderModalMoreView
          currentTab={currentTab}
          handleChangeTab={handleChangeTab}
        />
        <div className="overflow-y-auto max-h-[500px] mt-2.5">
          <Item />
          <Item isUnread />
          <Item />
          <Item isUnread />
          <Item isUnread />
          <Item isUnread />
          <Item />
          <Item isUnread />
          <Item />
          <Item isUnread />
          <Item />
          <Item isUnread />
          <Item />
          <Item isUnread />
          <Item isUnread />
        </div>
      </FormTera>
    </Modal>
  );
}

export default ModalNotification;
