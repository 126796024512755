import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SumiEndpoint = `${endpoint}/sumi`;
const PortalEndpoint = `${endpoint}/portal`;

const SumiApi = {
  getCategoryList: async ({ params }) =>
    await api
      .get(`${SumiEndpoint}/warehouse/catalog/list`, params)
      .then((result) => result.data?.data),
  getInvestorList: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/investor/list`, params)
      .then((result) => result.data?.data),
  getInvestmentList: async ({ params }) =>
    await api
      .get(`${SumiEndpoint}/invest/list`, params)
      .then((result) => result.data?.data),
  getEmployeeList: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/employee/list`, params)
      .then((result) => result.data?.data),
  getUnitList: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/product-unit/list`, params)
      .then((result) => result.data?.data),
  getOrderInspectionList: async ({ params }) =>
    await api
      .get(`${SumiEndpoint}/sell/order-checking/list`, params)
      .then((result) => result.data?.data),
  getIncurredCostCategory: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/incurred-cost-category/list`, params)
      .then((result) => result.data?.data),
  getBankList: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/finance/banks`, params)
      .then((result) => result.data),
  getDeviceList: async ({ params }) =>
    await api
      .get(`${PortalEndpoint}/device/list`, params)
      .then((result) => result.data?.data),
};

export default SumiApi;
