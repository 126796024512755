import { BellOutlined, Popover } from 'tera-dls';
import User from './User';
import UserNotification from './UserNotification';
import { useState } from 'react';
import ModalNotification from './UserNotification/ModalNotification';

const Header = () => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const listIcon = [
    <Popover
      content={<UserNotification onClick={() => setIsModal(true)} />}
      trigger="click"
      placement="bottom-end"
      className="w-[600px] border"
      showArrow={false}
    >
      <div className="relative">
        <BellOutlined className="w-6 h-6 cursor-pointer" />
        <div className="absolute w-2.5 h-2.5 rounded-full bg-red-500 top-0 right-0 border" />
      </div>
    </Popover>,
    <User />,
  ];
  return (
    <>
      <ul className="flex flex-row gap-2.5 pr-2.5">
        {listIcon.map((item, key) => (
          <li key={key} className="text-gray-500">
            {item}
          </li>
        ))}
      </ul>

      {isModal && (
        <ModalNotification open={isModal} onCancel={() => setIsModal(false)} />
      )}
    </>
  );
};
export default Header;
