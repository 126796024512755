import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCategory from '_common/dof/Select/SelectCategory';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate } from 'tera-dls';

interface FeedbackFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function FeedbackFilter({
  open,
  initialValue,
  onClose,
  onFilter,
}: FeedbackFilterProps) {
  const form = useForm({ mode: 'onChange' });
  const warehouse_catalog_id = form.watch('warehouse_catalog_id');

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      ...values,
      date: values?.date && formatDate(values?.date, DATE_BACKEND_FORMAT),
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      warehouse_catalog_id: null,
      date: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = {
      warehouse_catalog_id: initialValue?.warehouse_catalog_id,
      date:
        initialValue?.date && moment(initialValue?.date, DATE_BACKEND_FORMAT),
    };

    form.reset(values);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Danh mục đầu tư" name="warehouse_catalog_id">
          <SelectCategory paramsApi={{ include_id: warehouse_catalog_id }} />
        </FormTeraItem>
        <FormTeraItem label="Ngày đánh giá" name="date">
          <DatePicker />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default FeedbackFilter;
