import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import TableTera from '_common/dof/TableTera';
import useInfiniteScrollTable from '_common/dof/TableTera/Hooks/useInfiniteScrollTable';
import { ITableRowActionRef } from '_common/dof/TableTera/_interfaces';
import { OPERATION_KEY } from '_common/dof/TableTera/constants';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  formatCurrency,
  notification,
  PlusCircleOutlined,
  Spin,
} from 'tera-dls';

import { MAXIMUM_CURRENCY } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import _ from 'lodash';
import SalesExpensesItemApi from '../../api/salesExpensesItem';

type Mode = 'default' | 'soft' | 'view';
interface IProps {
  detail?: any;
  tableProps?: any;
  mode?: Mode;
  id?: number | string;
  limit?: number;
  objectType: string;
  summaryTotal?: number;
  investId?: number | string;
  onChange?: (value: any) => void;
  onTotalChange?: (value: any) => void;
  onSuccess?: () => void;
}

export interface ISalesExpensesFormTableRef {
  checkError: () => boolean;
  getTotal: () => number;
  getLengthData: () => number;
}

const SalesExpensesFormTable = (props: IProps, ref: any) => {
  const {
    detail,
    tableProps = {},
    mode = 'default',
    id,
    limit = 15,
    objectType,
    summaryTotal,
    investId,
    onChange,
    onSuccess,
    onTotalChange,
  } = props;

  const actionRef = useRef<ITableRowActionRef>(null);
  const [dataSource, setDataSource] = useState<any>([]);
  const queryClient = useQueryClient();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['sales-expanses-infinite-scroll', investId],
    staleTime: 30000,
    cacheTime: 30000,
    queryFn: ({ pageParam }) =>
      SalesExpensesItemApi.getList({
        params: {
          invest_id: investId,
          page: pageParam ?? 1,
          limit,
        },
      }),
    enabled: mode !== 'soft' && !!investId,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.last_page - 1 >= lastPage?.current_page
        ? allPages.length + 1
        : undefined;
    },
  });

  useInfiniteScrollTable({
    objectType,
    callback: () => {
      if (hasNextPage && !isFetching && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    enable: mode !== 'soft',
  });

  useEffect(() => {
    if (mode !== 'soft' && !!id) {
      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: [oldData?.pages?.[0] ?? []],
            pageParams: [1],
          };
        },
      );
      refetch();

      return () => {
        queryClient.invalidateQueries(['sales-expanses-infinite-scroll']);
      };
    }
  }, [mode, id]);

  const { mutateAsync: mutateSave, isLoading: isSaving } = useMutation(
    (variables: any) => {
      if (variables?.type === 'add')
        return SalesExpensesItemApi.create({ params: variables?.params });
      return SalesExpensesItemApi.update({
        id: variables?.id,
        params: variables?.params,
      });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-sales-expenses-detail']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutateAsync: mutateDelete } = useMutation(
    (variables: any) => SalesExpensesItemApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-sales-expenses-detail']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const value = useMemo(() => {
    return data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [data]);

  console.log(value);

  useEffect(() => {
    if (!value) return;
    mode !== 'soft' && setDataSource(value);
  }, [value, mode]);

  useEffect(() => {
    if (data?.pages?.length > 0 && mode !== 'soft') {
      const page = data?.pages?.[data?.pages?.length - 1];
      onTotalChange && onTotalChange(page?.data?.total);
    }
  }, [data?.pages, mode]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return actionRef.current?.trigger();
      },
      getTotal() {
        // return response?.total ?? 0;
      },
      getLengthData() {
        return dataSource?.length;
      },
    }),
    [actionRef, dataSource, ref],
  );

  const isDisable = (isSaving || isFetching || isFetchingNextPage) && !!id;

  const columns = [
    {
      title: 'Tên chi phí bán hàng',
      dataIndex: 'name',
      width: '50%',
      editable: true,
      rules: [{ required: messageValidate.emptyText }],
      inputProps: {
        autoFocus: true,
        maxLength: 255,
      },
      render: (val) => <span className="break-word">{val}</span>,
    },
    {
      title: 'Số tiền (đ)',
      dataIndex: 'price',
      width: '45%',
      type: 'int',
      editable: true,
      rules: [{ required: messageValidate.emptyText }],
      inputProps: {
        min: 0,
        max: MAXIMUM_CURRENCY,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      render: (val) => formatCurrency(val),
    },
    mode !== 'view' && {
      title: (
        <div className="flex justify-center">
          {isDisable ? (
            <div className="flex justify-center">
              <Spin
                spinning
                svgProps={{ className: 'w-4 h-[20px]' }}
                wrapperClassName=" h-[10px]"
              />
            </div>
          ) : (
            <PlusCircleOutlined
              className={`w-5 cursor-pointer text-green-500 table-row-except-cope ${
                isDisable ? '!text-gray-500' : ''
              }`}
              onClick={() => {
                !isDisable && actionRef.current?.addRow();
              }}
            />
          )}
        </div>
      ),
      dataIndex: OPERATION_KEY,
      width: 60,
    },
  ];

  const handleAdd = async (record) => {
    try {
      const response = await mutateSave({
        type: 'add',
        params: { sale_cost_id: id, price: record?.price, name: record?.name },
      });
      if (response?.code !== 200) return;
      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pageParams: [oldData?.pageParams?.[0]],
          };
        },
      );
      refetch({
        refetchPage: (page, index) => {
          return index === 0;
        },
      });
    } catch (e) {}
  };

  const handleDelete = async (record, index) => {
    if (dataSource.length === 1) {
      notification.error({
        message: 'Phải có ít nhất 1 chi phí bán hàng',
      });
      return;
    }
    try {
      const response = await mutateDelete({ id: record.id });
      const currentPage = Math.floor(index / limit) + 1;
      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;

      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdate = async (record) => {
    try {
      const response = await mutateSave({
        type: 'update',
        id: record?.id,
        params: {
          sale_cost_id: record?.sale_cost_id,
          price: record?.price,
          name: record?.name,
        },
      });
      const currentPage = Math.floor(record?.index / limit) + 1;

      if (!currentPage || currentPage <= 0 || response?.code !== 200) return;
      queryClient.setQueryData(
        ['sales-expanses-infinite-scroll'],
        (oldData: any) => {
          return {
            pages: oldData?.pages?.filter(
              (_, index) => index <= currentPage - 1,
            ),
            pageParams: oldData?.pageParams.filter(
              (_, index) => index <= currentPage - 1,
            ),
          };
        },
      );

      refetch({
        refetchPage: (page, index) => {
          return index === currentPage - 1;
        },
      });
    } catch (e) {}
  };

  const total = useMemo(() => {
    if (summaryTotal) return summaryTotal;
    if (detail)
      return detail?.sale_cost_items?.reduce(
        (total, current) => total + current?.price,
        0,
      );
    const sum = dataSource?.reduce((total, current) => {
      return total + current?.price;
    }, 0);
    return sum;
  }, [summaryTotal, detail, dataSource]);

  return (
    <>
      <TableTera
        className="rounded overflow-hidden"
        objectType={objectType}
        loading={isLoading && !!id}
        loadingIndicator={{
          loading: isFetchingNextPage,
        }}
        recordCreatorProps={{
          record: () => ({ price: 0 }),
        }}
        scroll={{ y: 360 }}
        {...tableProps}
        data={dataSource}
        columns={columns}
        rowKey={'id'}
        mode={mode === 'view' ? 'table' : 'editable-row'}
        actionRef={actionRef}
        editable={{
          ...(tableProps?.editable && tableProps?.editable),
          saveOnClickOut: true,
          onValuesChange(_, recordList) {
            if (mode !== 'default') {
              setDataSource(recordList);
              const value = [...(recordList ?? [])]?.map((item) => {
                const { id, ...rest } = item;
                id;
                return {
                  ...rest,
                };
              });
              onChange && onChange(value);
            }
          },
          buttonDeleteIndicator({ dataSource, node }) {
            if (dataSource?.length <= 1) return <></>;
            return node;
          },
          ...(mode == 'default' &&
            id && {
              onAdd: handleAdd,
              onDelete: handleDelete,
              onUpdate: handleUpdate,
              isDisabled: isDisable,
            }),
        }}
      />
      <div className="mt-2.5">
        <div className="ml-auto max-w-max p-2.5">
          <span className="text-base text-gray-800">Tổng tiền: </span>
          <span className="text-base text-green-500 font-medium">
            {formatCurrency(total)}
          </span>
        </div>
      </div>
    </>
  );
};

export default forwardRef<ISalesExpensesFormTableRef, IProps>(
  SalesExpensesFormTable,
);
