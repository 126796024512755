import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Spin } from 'tera-dls';
import HeaderModalMoreView from './Header';
import Item from './Item';

const UserNotification = ({ onClick }) => {
  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = (key) => {
    setCurrentTab(key);
  };

  return (
    <Spin spinning={false}>
      <HeaderModalMoreView
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
      />
      <div className="overflow-y-auto max-h-[600px] mt-2.5">
        <Item />
        <Item isUnread />
        <Item />
        <Item isUnread />
        <Item isUnread />
        <Item isUnread />
        <Item />
        <Item isUnread />
        <Item />
        <Item isUnread />
        <Item />
        <Item isUnread />
        <Item />
        <Item isUnread />
        <Item isUnread />
      </div>
      <div className="text-center border-t mt-2.5 pt-2.5">
        <span
          className="text-blue-600 hover:underline cursor-pointer"
          onClick={onClick}
        >
          Xem tất cả thông báo
        </span>
      </div>
    </Spin>
  );
};

export default observer(UserNotification);
