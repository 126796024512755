import { useQuery } from '@tanstack/react-query';
import DashboardApi from 'pages/Dashboard/api';
import { PORTFOLIO } from 'pages/Dashboard/constants';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import startUpIdea from 'styles/images/pages/finance/start_up_idea.png';
import {
  ChartBarProps,
  ChartPie,
  formatDate,
  formatNumber,
  Spin,
  useDetectDevice,
} from 'tera-dls';
import Header from '../../Header';
import ModalChart from './ModalChart';

export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
  params?: any;
}

export interface InvestmentRevenueRefs {
  refetchData?: () => void;
}

const Content = (props: IProps, ref) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', chartClassName, params, onClose } = props;
  const { widthScreen } = useDetectDevice();

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-investment-revenue-dashboard', params],
    () => DashboardApi.getInvestmentRevenue({ params }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  useImperativeHandle(
    ref,
    () => ({
      refetchData() {
        refetch();
      },
    }),
    [],
  );

  const dataSource = useMemo(() => {
    const labels = data?.group_by
      ? Object?.keys(data?.group_by)?.map(
          (value: any) => PORTFOLIO?.[value]?.name,
        )
      : [];
    const colors = data?.group_by
      ? Object?.keys(data?.group_by)?.map(
          (value: any) => PORTFOLIO?.[value]?.color,
        )
      : [];

    const datasets: any = {
      data: data?.group_by
        ? Object?.values(data?.group_by)?.map(
            (value: any) => value?.total_price_sum,
          )
        : [],
      backgroundColor: colors,
      borderWidth: 0,
    };
    return {
      labels,
      datasets: [datasets],
    };
  }, [data]);

  const options: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          const total = data?.total_price;
          const value = values?.dataset?.data[values?.dataIndex];

          const percent = (value / total) * 100;
          return `${percent.toFixed(1)}%`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 10,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: true,
      },
      maintainAspectRatio: false,
    },
  };

  const isSmallType = type === 'small';
  const checkData = (data) =>
    data?.datasets?.[0]?.data.some((value) => value !== 0);

  return (
    <Spin spinning={isLoading || isRefetching}>
      <Header
        title="Doanh thu theo danh mục đầu tư"
        isRefetch={false}
        isSetting={false}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
      />
      <p className="text-[#6B7280] text-[10px]">{`${formatDate(
        params?.date_from,
      )} - ${formatDate(params?.date_to)}`}</p>
      {params?.order_type !== 'order' && (
        <p>
          <span className="font-semibold text-green-500">
            {formatNumber(data?.total_price)}
          </span>{' '}
          <span className="text-[10px]">vnd</span>
        </p>
      )}

      <div className="flex justify-center">
        {!checkData(dataSource) || params?.order_type === 'order' ? (
          <div className="flex flex-col">
            <img src={startUpIdea} className="mb-[60px]" />
            <p className="text-gray-400 font-normal text-center w-full ">
              Chưa có dữ liệu
            </p>
          </div>
        ) : (
          <ChartPie
            key={widthScreen}
            data={dataSource}
            plugins={['data-labels']}
            options={options}
            className={chartClassName}
          />
        )}
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
          params={params}
        />
      )}
    </Spin>
  );
};

export default forwardRef<InvestmentRevenueRefs, IProps>(Content);
