import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DEFAULT_IMAGE,
  MAXIMUM_CURRENCY,
  REGEX,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal, notification, Row, Spin } from 'tera-dls';
import InvestmentApi from '../../api';
import Image from '_common/dof/Control/Image';
import { useMemo } from 'react';

interface InvestmentFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function InvestmentForm(props: InvestmentFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();

  const link_map = form.watch('link_map');

  const { mutate: mutateInvestment, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return InvestmentApi.update({ id, params });
      return InvestmentApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose();
          queryClient.invalidateQueries(['get-investment-list']);
          queryClient.invalidateQueries(['get-summary-investment-list']);
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    if (isLoadingMutate) return;
    const data = {
      ...values,
      file_upload: values?.file_upload || DEFAULT_IMAGE,
    };
    mutateInvestment(data);
  };

  const srcMap = useMemo(() => {
    const srcValue = link_map?.match(/src="([^"]*)"/)?.[1];
    return srcValue;
  }, [link_map]);

  return (
    <Modal
      title={id ? 'Sửa đầu tư' : 'Thêm đầu tư'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[90%] xl:w-[1000px]"
      confirmLoading={isLoadingMutate}
    >
      <Spin spinning={isLoadingMutate}>
        <FormTera form={form}>
          <Row className="grid grid-cols-2 gap-x-4 gap-y-0">
            <FormTeraItem
              label="Ảnh đại diện"
              className="flex flex-col col-span-2"
              name="file_upload"
            >
              <Image folder="investment" object_key="investment" />
            </FormTeraItem>
            <FormTeraItem
              label="Mã đầu tư"
              name="code"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.CODE),
                    message: messageValidate.code,
                  },
                  maxLength: {
                    value: 10,
                    message: 'Không nhập quá 10 kí tự',
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>

            <FormTeraItem
              label="Giá bán"
              name="amount"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <InputNumber min={0} max={MAXIMUM_CURRENCY} />
            </FormTeraItem>

            <FormTeraItem
              label="Tên đầu tư"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>

            <FormTeraItem
              label="Địa chỉ cụ thể"
              name="address"
              rules={[
                {
                  maxLength: {
                    value: 255,
                    message: messageValidateLength.textLong,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Danh mục đầu tư"
              name="type"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectInvestmentCategory />
            </FormTeraItem>

            <FormTeraItem label="Nhúng link Google map" name="link_map">
              <Input placeholder="Nhúng bản đồ" />
            </FormTeraItem>
            {srcMap && (
              <div className="iframe-maps col-span-2 border rounded-md mb-2.5 overflow-hidden">
                <iframe
                  src={srcMap}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            )}
            <FormTeraItem
              label="Mô tả"
              name="description"
              className="col-span-2"
              rules={[
                {
                  maxLength: {
                    value: 1000,
                    message: messageValidateLength.textArea,
                  },
                },
              ]}
            >
              <TextArea rows={10} />
            </FormTeraItem>
          </Row>
        </FormTera>
      </Spin>
    </Modal>
  );
}

export default InvestmentForm;
