import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY, REGEX } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import InvestmentTypeApi from '../../api';
import { INVESTMENT_TYPE } from '../../constants';

interface InvestmentTypeFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function InvestmentTypeForm(props: InvestmentTypeFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange', defaultValues: { status: true } });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-investment-type-detail', id],
    () => InvestmentTypeApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateInvestmentType, isLoading: isLoadingMutate } =
    useMutation(
      (params: any) => {
        if (id) return InvestmentTypeApi.update({ id, params });
        return InvestmentTypeApi.create({ params });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-summary-investment-type-list']);
            queryClient.invalidateQueries(['get-investment-type-list']);
            onClose();
            onSuccess && onSuccess();
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...value,
      status: value?.status ? 1 : 0,
    };
    mutateInvestmentType(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        name: dataDetails?.name,
        code: dataDetails?.code,
        type: dataDetails?.type,
        price: dataDetails?.price,
        status: dataDetails?.status == 1 ? true : false,
        file_upload: dataDetails?.file_upload,
      };
      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  const optionsInvestmentType = Object.entries(INVESTMENT_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Modal
      title={id ? 'Sửa loại hình đầu tư' : 'Thêm loại hình đầu tư'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Mã loại hình đầu tư"
              name="code"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.CODE),
                    message: messageValidate.code,
                  },
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input disabled={!!id} />
            </FormTeraItem>
            <FormTeraItem
              label="Tên loại hình đầu tư"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Loại đầu tư"
              name="type"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <Select options={optionsInvestmentType} />
            </FormTeraItem>
            <FormTeraItem
              label="Giá bán"
              name="price"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <InputNumber min={0} max={MAXIMUM_CURRENCY} />
            </FormTeraItem>

            <FormTeraItem name="status" className="col-span-2">
              <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default InvestmentTypeForm;
