import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ProductApi from 'pages/Sale/Product/api';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, FunnelOutlined, Pagination, Spin } from 'tera-dls';
import FeedbackFilter from './Filter';
import Item from './Item';
import FeedbackSearch from './Search';

function Feedback() {
  const { id } = useParams();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });

  const [filter, setFilter] = useState({ open: false, params: null });
  const [search, setSearch] = useState(null);

  const { data: feedbackList, isLoading } = useQuery(
    ['get-feedback-product-list', id, pagination, filter, search],
    () => {
      const params = {
        ...search,
        ...filter,
        ...pagination,
      };
      return ProductApi.getFeedback({ id, params });
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );
  setPagination;

  return (
    <div>
      <div className="mb-2.5 flex justify-end gap-x-2.5">
        <FeedbackSearch onSearch={setSearch} search={search?.keyword} />
        <Button
          type="alternative"
          className="rounded-xsm py-1 px-1 bg-blue-50"
          icon={
            <FunnelOutlined className="text-blue-500 rounded-[4px] shrink-0" />
          }
          onClick={() => setFilter((prev) => ({ ...prev, open: true }))}
        />
      </div>
      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-y-5">
          {feedbackList?.data?.length > 0 ? (
            <>
              {feedbackList?.data.map((feedback) => (
                <Item dataDetails={feedback} />
              ))}
              <Pagination classNameContainer="ml-auto" />
            </>
          ) : (
            <NoData />
          )}
        </div>
      </Spin>

      {filter.open && (
        <FeedbackFilter
          open={filter.open}
          onClose={() => setFilter((prev) => ({ ...prev, open: false }))}
          onFilter={(values) =>
            setFilter((prev) => ({
              ...prev,
              params: { ...prev.params, ...values },
            }))
          }
          initialValue={filter.params}
        />
      )}
    </div>
  );
}

export default Feedback;
