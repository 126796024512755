import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import {
  DATE_TIME_FORMAT,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import {
  ColumnsType,
  DropdownItem,
  formatDate,
  Image,
  notification,
  Tag,
} from 'tera-dls';
import { INVESTOR_URL } from '../../url';
import { useNavigate } from 'react-router-dom';
import useConfirm from '_common/hooks/useConfirm';
import InvestorForm from '../Form';
import { useState } from 'react';
import ModalChangePassword from '_common/component/ModalChangePassword';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InvestorApi from '../../api';
import { usePermission } from '_common/hooks/usePermission';
import { INVESTOR_PERMISSION_KEY } from '_common/constants/permission';

function InvestorTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openResetPassword, setOpenResetPassword] = useState({
    open: false,
    id: null,
  });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => InvestorApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investor-list']);
          queryClient.invalidateQueries(['get-summary-investor-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => InvestorApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investor-list']);
          queryClient.invalidateQueries(['get-summary-investor-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangeStatus = (record) => {
    const text = record?.status == 1 ? 'ngưng' : 'mở';
    confirm.warning({
      title: `Xác nhận ${text} hoạt động nhà đầu tư`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text} hoạt động nhà đầu tư</p>
          <p>
            <b>{record?.investor_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: record?.id,
          status: record?.status == 1 ? 0 : 1,
        };
        mutateChangeStatus(params);
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa nhà đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa nhà đầu tư</p>
          <p>
            <b>{record?.investor_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(INVESTOR_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${INVESTOR_URL.detail.path}/${record?.id}`),
      });
    if (hasPage(INVESTOR_PERMISSION_KEY.CHANGE_STATUS))
      items.push({
        key: '2',
        label: record?.status == 1 ? 'Ngưng hoạt động' : 'Hoạt động',
        onClick: () => handleChangeStatus(record),
      });
    if (hasPage(INVESTOR_PERMISSION_KEY.CHANGE_PASSWORD))
      items.push({
        key: '3',
        label: 'Đổi mật khẩu',
        onClick: () =>
          setOpenResetPassword({ open: true, id: record?.user_id }),
      });
    if (hasPage(INVESTOR_PERMISSION_KEY.UPDATE))
      items.push({
        key: '4',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(INVESTOR_PERMISSION_KEY.UPDATE) && !record?.status)
      items.push({
        key: '5',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Hình ảnh',
      dataIndex: 'file_upload',
      width: 50,
      render: (file_upload) =>
        file_upload && (
          <Image
            src={file_upload}
            alt={file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border"
            imageClassName="object-cover"
          />
        ),
    },
    {
      title: 'Tên nhà đầu tư',
      dataIndex: 'investor_name',
      width: 300,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'SL đã đầu tư',
      dataIndex: 'vehicle_count',
      width: 100,
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      width: 100,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || loadingChangeStatus || loadingDelete}
        {...props}
      />
      {openForm.open && (
        <InvestorForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
      {openResetPassword.open && (
        <ModalChangePassword
          userId={openResetPassword?.id}
          open={openResetPassword.open}
          onClose={() => setOpenResetPassword({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default InvestorTable;
