import { useMutation, useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  DEFAULT_IMAGE,
  GENDER,
  REGEX,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DatePickerProps, Modal, notification, Spin } from 'tera-dls';
import EmployeeApi from '../../api';
import DatePicker from '_common/dof/Control/DatePicker';
import moment from 'moment';

interface EmployeeFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function EmployeeForm(props: EmployeeFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange', defaultValues: { status: true } });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-employee-detail', id],
    () => EmployeeApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateEmployee, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return EmployeeApi.update({ id, params });
      return EmployeeApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...value,
      status: !!value?.status ? 1 : 0,
      birthday:
        value?.birthday && moment(value?.birthday).format(DATE_BACKEND_FORMAT),
      file_upload: value?.file_upload || DEFAULT_IMAGE,
    };
    mutateEmployee(data);
  };

  const optionsGender = Object.entries(GENDER).map(([value, label]) => ({
    label,
    value,
  }));

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    const today = moment();
    return current && current > today;
  };

  useEffect(() => {
    if (dataDetails) {
      const data = {
        file_upload: dataDetails?.file_upload,
        code: dataDetails?.code,
        name: dataDetails?.name,
        phone: dataDetails?.phone,
        gender: dataDetails?.gender,
        email: dataDetails?.email,
        address: dataDetails?.address,
        status: !!dataDetails?.status ? true : false,
        birthday: dataDetails?.birthday && moment(dataDetails?.birthday),
      };
      form.reset(data);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa nhân viên' : 'Thêm nhân viên'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Ảnh đại diện"
              className="flex flex-col col-span-2"
              name="file_upload"
            >
              <Image folder="employee" object_key="employee" />
            </FormTeraItem>
            <FormTeraItem
              label="Mã nhân viên"
              name="code"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.CODE),
                    message: messageValidate.code,
                  },
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Họ và tên"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem label="Ngày sinh" name="birthday">
              <DatePicker format={DATE_FORMAT} disabledDate={disabledDate} />
            </FormTeraItem>
            <FormTeraItem
              label="Số điện thoại"
              name="phone"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PHONE_NUMBER),
                    message: messageValidate.phone,
                  },
                },
              ]}
            >
              <Input disabled={!!id} />
            </FormTeraItem>
            {!id && (
              <FormTeraItem
                label="Mật khẩu"
                name="password"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.PASSWORD),
                      message: messageValidate.password,
                    },
                    minLength: {
                      value: 8,
                      message: messageValidate.min_length_password,
                    },
                    maxLength: {
                      value: 16,
                      message: messageValidate.max_length_password,
                    },
                  },
                ]}
              >
                <InputPassword type="confirm-password" />
              </FormTeraItem>
            )}
            <FormTeraItem label="Giới tính" name="gender">
              <Select options={optionsGender} />
            </FormTeraItem>
            <FormTeraItem
              label="Email liên hệ"
              name="email"
              rules={[
                {
                  pattern: {
                    value: new RegExp(REGEX.EMAIL),
                    message: messageValidate.email,
                  },
                  maxLength: {
                    value: 100,
                    message: messageValidateLength.text,
                  },
                },
              ]}
            >
              <Input type="confirm-password" />
            </FormTeraItem>
            <FormTeraItem
              label="Địa chỉ"
              name="address"
              rules={[
                {
                  maxLength: {
                    value: 255,
                    message: messageValidateLength.textLong,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>

            <FormTeraItem name="status" className="col-span-2">
              <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default EmployeeForm;
