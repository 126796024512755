import { DATE_TIME_FORMAT } from '_common/constants/common';
import classNames from 'classnames';
import moment from 'moment';
import { CodeBracketSquareOutlined, formatDate } from 'tera-dls';

interface ItemProps {
  isUnread?: boolean;
}

function Item({ isUnread }: ItemProps) {
  return (
    <div
      className={classNames(
        'p-2.5 flex justify-between cursor-pointer hover:bg-gray-50',
        {
          'bg-blue-50 hover:!bg-blue-100': isUnread,
        },
      )}
    >
      <div className="flex items-center gap-x-2.5">
        <CodeBracketSquareOutlined className="w-5 h-5 shrink-0" />
        <div>
          <p className="text-sm font-medium line-clamp-1 break-word">
            Nguyễn Văn A
          </p>
          <p className="text-xs line-clamp-1 break-word">Đã tạo đơn bán hàng</p>
        </div>
      </div>
      <p className="text-xs text-gray-500 shrink-0">
        {formatDate(moment(), DATE_TIME_FORMAT)}
      </p>
    </div>
  );
}

export default Item;
