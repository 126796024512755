import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { INVESTMENT_TYPE } from 'pages/Invest/InvestmentType/constants';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  formatCurrency,
  formatDate,
  Image,
  InformationCircleOutlined,
  ItemType,
  notification,
  PencilSquareOutlined,
  Spin,
  Tag,
  Tooltip,
  XMarkOutlined,
} from 'tera-dls';
import IncurredCostApi from '../../api';
import {
  INCURRED_COST_STATUS,
  INCURRED_COST_STATUS_COLOR,
} from '../../constants';
import { INCURRED_COST_URL } from '../../url';
import IncurredCostForm from '../Form';
import { usePermission } from '_common/hooks/usePermission';
import { INCURRED_COST_PERMISSION_KEY } from '_common/constants/permission';

function IncurredCostDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const [openForm, setOpenForm] = useState<boolean>(false);

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-incurred-cost-detail', id],
    () => IncurredCostApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => IncurredCostApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-incurred-cost-list']);
          queryClient.invalidateQueries(['get-summary-incurred-cost-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => IncurredCostApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
          queryClient.invalidateQueries(['get-incurred-cost-list']);
          queryClient.invalidateQueries(['get-summary-incurred-cost-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const handleChangeStatus = (status: 'reject' | 'approved') => {
    const text = {
      reject: 'từ chối',
      approve: 'duyệt',
    };
    confirm.warning({
      title: `Xác nhận ${text[status]} chi phí phát sinh`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text[status]} chi phí phát sinh</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateChangeStatus({
          id: id,
          status,
          type_status: 'sumi',
        }),
    });
  };

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa sản phẩm',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa sản phẩm</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const detail = [
    {
      label: 'Mã chi phí phát sinh',
      value: dataDetails?.code,
    },
    {
      label: 'Danh mục CPPS',
      value: dataDetails?.incurred_cost_cate?.name,
    },
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Đầu tư',
      value: dataDetails?.invest_name,
    },
    {
      label: 'Chi phí phát sinh',
      value: formatCurrency(dataDetails?.amount),
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Nhà đầu tư',
      value: dataDetails?.investor_name,
    },
    {
      label: 'Lý do',
      value: dataDetails?.note,
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_TYPE[dataDetails?.type],
    },
    {
      label: 'Trạng thái',
      value: dataDetails?.status && (
        <div className="flex gap-x-2.5 items-center">
          <Tag color={INCURRED_COST_STATUS_COLOR[dataDetails?.status]}>
            {INCURRED_COST_STATUS[dataDetails?.status]}
          </Tag>
          {dataDetails?.type_status === 'sumi' && (
            <Tooltip
              title={`SUMI ${INCURRED_COST_STATUS[dataDetails?.status]}`}
              placement="top"
            >
              <div>
                <InformationCircleOutlined className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Tên thiết bị',
      value: dataDetails?.device_name,
    },
  ];

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(INCURRED_COST_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách chi phí phát sinh
        </a>
      ),
    },
    {
      title: 'Chi tiết chi phí phát sinh',
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {dataDetails?.status === 'pending' &&
              hasPage(INCURRED_COST_PERMISSION_KEY.CHANGE_STATUS) && (
                <>
                  <Button
                    type="danger"
                    className="page-header-v2-btn"
                    onClick={() => handleChangeStatus('reject')}
                    disabled={isError || isLoading}
                  >
                    Từ chối
                  </Button>
                  <Button
                    type="success"
                    className="page-header-v2-btn"
                    onClick={() => handleChangeStatus('approved')}
                    disabled={isError || isLoading}
                  >
                    Duyệt
                  </Button>
                </>
              )}
            {dataDetails?.status !== 'approved' && (
              <>
                {hasPage(INCURRED_COST_PERMISSION_KEY.UPDATE) && (
                  <Button
                    className="page-header-v2-btn"
                    prefix={<PencilSquareOutlined />}
                    onClick={() => setOpenForm(true)}
                    disabled={isError || isLoading}
                  >
                    Sửa
                  </Button>
                )}
                {hasPage(INCURRED_COST_PERMISSION_KEY.DELETE) && (
                  <Button
                    className="page-header-v2-btn"
                    type="danger"
                    prefix={<XMarkOutlined />}
                    onClick={handleDelete}
                    disabled={isError || isLoading}
                  >
                    Xóa
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading || loadingDelete || loadingChangeStatus}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] p-2.5 shadow flex flex-col gap-y-2.5">
            <div
              className={classNames(
                'px-2.5 py-5 rounded-md border border-blue-400',
                SUMI_SHADOW,
              )}
            >
              <CardFormV2 title="Thông tin chung">
                <Row className="grid grid-cols-3 gap-2.5">
                  {detail.map((item, index) => (
                    <div className="flex items-start" key={index}>
                      <div className="detail-key">{item.label}</div>
                      <div className="detail-value">{item.value}</div>
                    </div>
                  ))}
                </Row>
              </CardFormV2>
            </div>
            <div
              className={classNames(
                'px-2.5 py-5 rounded-md border border-blue-400',
                SUMI_SHADOW,
              )}
            >
              <CardFormV2 title="Hình ảnh">
                {dataDetails?.file_upload?.length > 0 ? (
                  <div className="flex gap-x-5 flex-wrap">
                    {dataDetails?.file_upload?.map((file) => (
                      <Image
                        containerClassName="w-[100px] h-[100px] rounded overflow-hidden border bg-gray-100"
                        src={file?.url}
                        alt={file?.name}
                      />
                    ))}
                  </div>
                ) : (
                  <NoData />
                )}
              </CardFormV2>
            </div>
          </div>
        </div>
      </Spin>
      {openForm && (
        <IncurredCostForm
          open={openForm}
          id={id}
          onClose={() => setOpenForm(false)}
          onSuccess={refetch}
        />
      )}
    </div>
  );
}

export default IncurredCostDetail;
