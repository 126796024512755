import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  formatDate,
  ItemType,
  notification,
  PencilSquareOutlined,
  Row,
  Spin,
  XMarkOutlined,
} from 'tera-dls';
import SalesExpensesApi from '../../api';
import { SALES_EXPENSES_URL } from '../../url';
import SalesExpensesFormTable from '../Form/SalesExpensesFormTable';
import { SALES_EXPENSES_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function SalesExpensesDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-sales-expenses-detail', id],
    () => SalesExpensesApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => SalesExpensesApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-sales-expenses-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa chi phí bán hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa chi phí bán hàng của đầu tư</p>
          <p>
            <b>{dataDetails?.invest_id?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(SALES_EXPENSES_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách nhà đầu tư
        </a>
      ),
    },
    {
      title: 'Chi tiết nhà đầu tư',
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  const detail = [
    {
      label: 'Tên nhà đầu tư',
      value: dataDetails?.invest_id?.investor_id
        ? dataDetails?.invest_id?.investor?.investor_name
        : 'Hệ thống',
    },
    {
      label: 'Danh mục đầu tư',
      value:
        dataDetails?.invest_id?.type &&
        INVESTMENT_CATEGORY[dataDetails?.invest_id?.type],
    },
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Đầu tư',
      value: dataDetails?.invest_id?.name,
    },
    {
      label: 'Tên thiết bị bán hàng',
      value: dataDetails?.invest_id?.device?.full_name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ghi chú',
      value: dataDetails?.note,
    },
    {
      label: '',
      value: '',
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: '',
      value: '',
    },
    {
      label: '',
      value: '',
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  return (
    <Spin spinning={isLoading || loadingDelete}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <IconButton
                icon={<ArrowSmallLeftOutlined />}
                callBack={() => navigate(-1)}
              />
              <Breadcrumb separator="/" items={BreadcrumbItem} />
            </div>
            <div className="page-header-v2__function">
              {hasPage(SALES_EXPENSES_PERMISSION_KEY.DELETE) && (
                <Button
                  className="page-header-v2-btn"
                  prefix={<PencilSquareOutlined />}
                  onClick={() =>
                    navigate(`${SALES_EXPENSES_URL.update.path}/${id}`)
                  }
                  disabled={isError}
                >
                  Sửa
                </Button>
              )}

              {hasPage(SALES_EXPENSES_PERMISSION_KEY.DELETE) && (
                <Button
                  className="page-header-v2-btn"
                  type="danger"
                  prefix={<XMarkOutlined />}
                  onClick={handleDelete}
                  disabled={isError}
                >
                  Xóa
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow flex flex-col gap-y-2.5">
            <div
              className={classNames(
                'px-2.5 py-5 rounded-md border border-blue-400',
                SUMI_SHADOW,
              )}
            >
              <CardFormV2 title="Thông tin chung">
                <Row className="grid grid-cols-3 gap-2.5">
                  {detail.map((item, index) => (
                    <div className="flex items-start" key={index}>
                      <div className="detail-key">{item.label}</div>
                      <div className="detail-value">{item.value}</div>
                    </div>
                  ))}
                </Row>
              </CardFormV2>
            </div>
            <div
              className={classNames(
                'px-2.5 py-5 rounded-md border border-blue-400',
                SUMI_SHADOW,
              )}
            >
              <CardFormV2 title="Chi phí bán hàng">
                <SalesExpensesFormTable
                  investId={dataDetails?.invest_id?.id}
                  mode="view"
                  objectType="sales-expenses"
                  summaryTotal={dataDetails?.sale_cost_items?.total_price}
                />
              </CardFormV2>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default SalesExpensesDetail;
