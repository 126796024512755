import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatDate,
  formatNumber,
  notification,
  Tag,
} from 'tera-dls';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { ORDER_INSPECTION_URL } from '../../url';
import useConfirm from '_common/hooks/useConfirm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import OrderInspectionApi from '../../api';
import { usePermission } from '_common/hooks/usePermission';
import { ORDER_INSPECTION_PERMISSION_KEY } from '_common/constants/permission';

function OrderInspectionTable(props: ITeraTableProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const { mutate: mutateApprove, isLoading: loadingApprove } = useMutation(
    (id: any) => OrderInspectionApi.approve({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-order-inspection-list']);
          queryClient.invalidateQueries(['get-summary-order-inspection-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleApprove = (record) => {
    confirm.warning({
      title: 'Xác nhận duyệt đơn kiểm hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xác nhận duyệt đơn kiểm hàng</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateApprove(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(ORDER_INSPECTION_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          navigate(`${ORDER_INSPECTION_URL.detail.path}/${record?.id}`),
      });
    if (
      hasPage(ORDER_INSPECTION_PERMISSION_KEY.CHECKING) &&
      record?.order_status === 'pending'
    )
      items.push({
        key: '2',
        label: 'Kiểm hàng',
        onClick: () =>
          navigate(`${ORDER_INSPECTION_URL.inspection.path}/${record?.id}`),
      });
    if (
      hasPage(ORDER_INSPECTION_PERMISSION_KEY.APPROVE) &&
      record?.order_status === 'pending'
    )
      items.push({
        key: '3',
        label: 'Duyệt',
        onClick: () => handleApprove(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đơn',
      dataIndex: 'code',
      width: 100,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_name',
      width: 150,
      render: (invest_name) => <p className="line-clamp-2">{invest_name}</p>,
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (type) => INVESTMENT_CATEGORY[type],
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_name',
      width: 200,
      render: (investor_name) => (
        <p className="line-clamp-2 break-word">{investor_name || 'Hệ thống'}</p>
      ),
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      width: 150,
      render: (device_name) => <p className="line-clamp-2">{device_name}</p>,
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'ticket_type',
      width: 100,
      render: (ticket_type) => TICKET_TYPE[ticket_type],
    },
    {
      title: 'Tổng SL thực nhận',
      dataIndex: 'sum_quantity_check',
      width: 100,
      render: (sum_quantity_check) =>
        formatNumber(sum_quantity_check?.total_quantity_check),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) => created_at && formatDate(created_at, DATE_FORMAT),
    },
    {
      title: 'Trạng thái hệ thống',
      dataIndex: 'order_status',
      width: 100,
      render: (status) =>
        status && (
          <Tag color={ORDER_STATUS_COLOR[status]}>{ORDER_STATUS[status]}</Tag>
        ),
    },
    {
      title: 'Trạng thái nhà đầu tư',
      dataIndex: 'investor_order_status',
      width: 100,
      render: (status) =>
        status && (
          <Tag color={ORDER_STATUS_COLOR[status]}>{ORDER_STATUS[status]}</Tag>
        ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        {...props}
        loading={props.loading || loadingApprove}
      />
    </>
  );
}

export default OrderInspectionTable;
