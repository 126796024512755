import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import TableTera from '_common/dof/TableTera';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  TabItemType,
  Tag,
  updateQueryParams,
} from 'tera-dls';

import ModalChangePassword from '_common/component/ModalChangePassword';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
  TAB_ITEMS_STATUS_RENDER,
} from '_common/constants/common';
import useConfirm from '_common/hooks/useConfirm';
import EmployeeApi from './api';
import EmployeeDetail from './containers/Detail';
import EmployeeForm from './containers/Form';
import EmployeeSearch from './containers/Search';
import { EMPLOYEE_URL } from './url';
import { usePermission } from '_common/hooks/usePermission';
import { EMPLOYEE_PERMISSION_KEY } from '_common/constants/permission';

function Employee() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });
  const [openResetPassword, setOpenResetPassword] = useState({
    open: false,
    id: null,
  });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${EMPLOYEE_URL.list.path}${paramString}`);
  };

  const {
    data: employeeList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-employee-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return EmployeeApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-employee-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return EmployeeApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => EmployeeApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-employee-list']);
          queryClient.invalidateQueries(['get-summary-employee-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(employeeList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };
  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa nhân viên',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa nhân viên</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(EMPLOYEE_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(EMPLOYEE_PERMISSION_KEY.CHANGE_PASSWORD))
      items.push({
        key: '2',
        label: 'Đổi mật khẩu',
        onClick: () =>
          setOpenResetPassword({ open: true, id: record?.user_id }),
      });
    if (hasPage(EMPLOYEE_PERMISSION_KEY.UPDATE))
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(EMPLOYEE_PERMISSION_KEY.DELETE) && !record?.status)
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: any = [
    {
      title: 'Mã nhân viên',
      dataIndex: 'code',
      render: (code) => <p className="line-clamp-2">{code}</p>,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Email liên hệ',
      dataIndex: 'email',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TAB_ITEMS_STATUS_RENDER.map((item) => {
      const count =
        summaryList?.find((i) => i?.status == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TAB_ITEMS_STATUS[item]} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách nhân viên"
        buttonFilterKey={EMPLOYEE_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={EMPLOYEE_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        middleContent={<SumiTab onChange={handleChangeTab} items={tabItems} />}
        actionLeftRender={
          hasPage(EMPLOYEE_PERMISSION_KEY.SEARCH) && (
            <EmployeeSearch onSearch={handleSearch} />
          )
        }
      >
        <TableTera
          rowKey={'id'}
          columns={columns}
          data={employeeList?.data || [{ id: 1 }]}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: employeeList?.total || 0,
            current: employeeList?.current_page,
            pageSize: employeeList?.per_page || 10,
            to: employeeList?.to,
            from: employeeList?.from || 10,
          }}
        />
      </HeaderViewList>

      {openDetail.open && (
        <EmployeeDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <EmployeeForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-employee-list']);
            queryClient.invalidateQueries(['get-summary-employee-list']);
          }}
        />
      )}
      {openResetPassword.open && (
        <ModalChangePassword
          userId={openResetPassword?.id}
          open={openResetPassword.open}
          onClose={() => setOpenResetPassword({ open: false, id: null })}
        />
      )}
    </div>
  );
}

export default Employee;
