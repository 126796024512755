import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  TabItemType,
  updateQueryParams,
} from 'tera-dls';
import InvestmentTypeApi from './api';
import InvestmentTypeFilter from './containers/Filter';
import InvestmentTypeForm from './containers/Form';
import InvestmentTypeSearch from './containers/Search';
import InvestmentTypeTable from './containers/Table';
import { INVESTMENT_TYPE_URL } from './url';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_RENDER,
} from '_common/constants/common';
import { usePermission } from '_common/hooks/usePermission';
import { INVESTMENT_TYPE_PERMISSION_KEY } from '_common/constants/permission';

function InvestmentType() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVESTMENT_TYPE_URL.list.path}${paramString}`);
  };

  const {
    data: investmentTypeList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-investment-type-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return InvestmentTypeApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-investment-type-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: undefined,
      };
      return InvestmentTypeApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(investmentTypeList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };
  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    if (!summaryList) return [];
    const tabs = TAB_ITEMS_STATUS_RENDER.map((item) => {
      const count =
        summaryList?.find((i) => i?.status == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TAB_ITEMS_STATUS[item]} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách loại hình đầu tư"
        buttonFilterKey={INVESTMENT_TYPE_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={INVESTMENT_TYPE_PERMISSION_KEY.CREATE}
        onClickFilter={() => setIsOpenFilter(true)}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        middleContent={
          <SumiTab
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
          />
        }
        actionLeftRender={
          hasPage(INVESTMENT_TYPE_PERMISSION_KEY.SEARCH) && (
            <InvestmentTypeSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: ['date', 'price_from', 'price_to'],
        }}
      >
        <InvestmentTypeTable
          rowKey={'id'}
          data={investmentTypeList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: investmentTypeList?.total || 0,
            current: investmentTypeList?.current_page,
            pageSize: investmentTypeList?.per_page || 10,
            to: investmentTypeList?.to,
            from: investmentTypeList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <InvestmentTypeFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openForm.open && (
        <InvestmentTypeForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </div>
  );
}

export default InvestmentType;
