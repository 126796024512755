import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { formatDate, Image, Modal, Spin, Tag } from 'tera-dls';
import UserApi from '../../api';
import { useEffect } from 'react';
import {
  DATE_TIME_FORMAT,
  GENDER,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';

interface UserDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function UserDetail({ id, open, onCancel }: UserDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const detail = [
    {
      label: 'Mã nhân viên',
      value: dataDetails?.code,
    },
    {
      label: 'Họ và tên',
      value: dataDetails?.name,
    },
    {
      label: 'Ngày sinh',
      value: formatDate(dataDetails?.birthday),
    },
    {
      label: 'Số điện thoại',
      value: dataDetails?.phone,
    },
    {
      label: 'Giới tính',
      value: dataDetails?.gender && GENDER[dataDetails?.gender],
    },
    {
      label: 'Email liên hệ',
      value: dataDetails?.email,
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
    {
      label: 'Địa chỉ',
      value: dataDetails?.address,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Modal
      title="Chi tiết người dùng"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-y-2.5">
            <Image
              src={dataDetails?.file_upload}
              alt={dataDetails?.file_upload}
              containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden m-auto"
            />
            {detail.map((item, index) => (
              <div className="flex items-start" key={index}>
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default UserDetail;
