import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { ColumnsType, DropdownItem, notification, Tag } from 'tera-dls';
import IncurredCostCategoryApi from '../../api';
import IncurredCostCategoryDetail from '../Detail';
import IncurredCostCategoryForm from '../Form';
import { usePermission } from '_common/hooks/usePermission';
import { INCURRED_COST_CATEGORY_PERMISSION_KEY } from '_common/constants/permission';

function IncurredCostCategoryTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation(
      (params: any) => IncurredCostCategoryApi.changeStatus({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-incurred-cost-category-list']);
            queryClient.invalidateQueries([
              'get-summary-incurred-cost-category-list',
            ]);
          }
        },
        onError: (error: any) => {
          notification.error({
            message: error?.message,
          });
        },
      },
    );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => IncurredCostCategoryApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-incurred-cost-category-list']);
          queryClient.invalidateQueries([
            'get-summary-incurred-cost-category-list',
          ]);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangeStatus = (record: any) => {
    const text = record?.status == 1 ? 'ngưng' : 'mở';
    confirm.warning({
      title: `Xác nhận ${text} hoạt động danh mục chi phí phát sinh`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text} hoạt động danh mục chi phí phát sinh</p>
          <p>
            <b>{record?.investor_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: record?.id,
          status: record?.status == 1 ? 0 : 1,
        };
        mutateChangeStatus(params);
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa nhà đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa danh mục chi phí phát sinh</p>
          <p>
            <b>{record?.investor_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(INCURRED_COST_CATEGORY_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(INCURRED_COST_CATEGORY_PERMISSION_KEY.CHANGE_STATUS))
      items.push({
        key: '2',
        label: record.status === 1 ? 'Ngưng hoạt động' : 'Hoạt động',
        onClick: () => handleChangeStatus(record),
      });
    if (hasPage(INCURRED_COST_CATEGORY_PERMISSION_KEY.UPDATE))
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (
      hasPage(INCURRED_COST_CATEGORY_PERMISSION_KEY.DELETE) &&
      !record?.status
    )
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tên danh mục chi phí phát sinh',
      dataIndex: 'name',
    },
    {
      width: 300,
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: number) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record: any) => (
        <ActionDropdown
          dropdownItems={renderItemActions(record)}
          trigger="click"
        />
      ),
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || loadingChangeStatus || loadingDelete}
        {...props}
      />
      {openDetail.open && (
        <IncurredCostCategoryDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <IncurredCostCategoryForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default IncurredCostCategoryTable;
