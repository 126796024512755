import { useEffect } from 'react';
// redux
import { useStores } from '_common/hooks';
// component
import PageLoading from '_common/component/PageLoading';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, notification } from 'tera-dls';

const CheckAuthPage = () => {
  const navigate = useNavigate();
  const {
    authStore: { auth_url },
  } = useStores();
  const location = useLocation();
  const params: { [key: string]: any } = getQueryParams(location.search);

  // const { mutate: onCheckAuth, isLoading } = useMutation(
  //   (variables) => AuthApi.checkAuth(variables),
  //   {
  //     onSuccess: (res) => {
  //       updateUser(res?.data);
  //       setTimeout(() => {
  //         navigate('/');
  //       }, 200);
  //     },
  //     onError: (error: any) => {
  //       const errorMessage = error?.data?.msg ?? 'Error!! please try again!';
  //       notification.error({
  //         message: errorMessage,
  //       });
  //     },
  //   },
  // );

  useEffect(() => {
    try {
      // if (!auth_url) return;
      if (params?.client_id) {
        const parseReq = JSON.parse(params?.req);
        if (parseReq?.iv) {
          setTimeout(() => {
            navigate('/');
          }, 200);

          // onCheckAuth(parseReq);
        }
      } else {
        navigate('/auth/login');
      }
    } catch (error) {
      notification.error({
        message: error?.message || 'Lỗi bất thường trong quá trình login',
      });
    }
  }, [auth_url, params?.client_id]);

  return <PageLoading />;
};

export default observer(CheckAuthPage);
