import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, PaginationProps, updateQueryParams } from 'tera-dls';
import SaleOrderApi from './api';
import SaleOrderFilter from './containers/Filter';
import SaleOrderSearch from './containers/Search';
import SaleOrderTable from './containers/Table';
import { SALE_ORDER_URL } from './url';
import { SALE_ORDER_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function SaleOrder() {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { hasPage } = usePermission();

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SALE_ORDER_URL.list.path}${paramString}`);
  };

  const {
    data: saleOrderList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-sale-order-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return SaleOrderApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(saleOrderList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách đơn bán hàng"
        buttonFilterKey={SALE_ORDER_PERMISSION_KEY.SEARCH}
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={
          hasPage(SALE_ORDER_PERMISSION_KEY.SEARCH) && (
            <SaleOrderSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: [
            'type',
            'investor_id',
            'type_invest',
            'quantity_from',
            'quantity_to',
            'amount_from',
            'amount_to',
          ],
        }}
      >
        <SaleOrderTable
          rowKey={'id'}
          data={saleOrderList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: saleOrderList?.total || 0,
            current: saleOrderList?.current_page,
            pageSize: saleOrderList?.per_page || 10,
            to: saleOrderList?.to,
            from: saleOrderList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <SaleOrderFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
}

export default SaleOrder;
