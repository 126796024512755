import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconPasswordStar } from 'styles/images/Icons/password-star.svg';
import { ReactComponent as IconSumiBike } from 'styles/images/Icons/sumi-bike.svg';
import { ReactComponent as IconSumiTitle } from 'styles/images/Icons/sumi-title.svg';
import { ReactComponent as IconUserCircle } from 'styles/images/Icons/user-circle.svg';
import { ReactComponent as IconUserLogin } from 'styles/images/Icons/user-login.svg';
import {
  Button,
  Form,
  FormItem,
  Input,
  InputPassword,
  notification,
  Row,
  Col,
  Spin,
  updateQueryParams,
} from 'tera-dls';
import * as yup from 'yup';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';
import { AuthApi } from 'states/api';

const schema = yup
  .object({
    username: yup.string().required('Bắt buộc'),
    password: yup.string().required('Bắt buộc'),
  })
  .required();

const LoginPage = (): JSX.Element => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate, isLoading } = useMutation(
    (params: any) => AuthApi.login({ params }),
    {
      onSuccess: (res) => {
        if (!(res?.data?.user?.type === 'owner')) {
          notification.error({
            message: 'Tài khoản không có quyền truy cập',
          });
          return;
        }

        const bodyParams = {
          access_id: res?.data?.access_id,
        };
        const queryParams = updateQueryParams({
          client_id: 'tera',
          req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
        });
        navigate(`/auth/check-auth${queryParams}`);

        authStore.updateUser(res?.data);
        reset();
      },
      onError: (error: any) => {
        const errorMessage = error?.data?.msg ?? 'Error!! please try again!';
        notification.error({
          message: errorMessage,
        });
      },
    },
  );

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <Spin spinning={isLoading}>
      <Row className="flex flex-col md:flex-row min-h-[100vh]">
        <Col className="md:w-[50%] flex justify-center items-center bg-[#FAD209]">
          <IconSumiBike />
        </Col>
        <Row className="md:w-[50%] flex flex-col justify-center items-center gap-[3rem]">
          <Row className="flex flex-col items-center gap-6">
            <IconSumiTitle />
            <IconUserCircle />
            <p className="text-[24px] text-[#618BA7] tracking-[6px] font-UTMBryantLG">
              Đăng nhập
            </p>
          </Row>
          <Form
            className="flex flex-col items-center gap-2 font-Poppins"
            onSubmit={handleSubmit(handleLogin)}
          >
            <FormItem
              messages={errors?.username?.message}
              isError={!!errors?.username}
            >
              <Input
                placeholder="Tên đăng nhập"
                className="w-[342px] h-[52px] bg-[#f3f4f6] pl-[50px] border-0 rounded-[16px]"
                prefix={<IconUserLogin className="w-[24px] h-[24px]" />}
                {...register('username')}
              />
            </FormItem>
            <FormItem
              messages={errors?.password?.message}
              isError={!!errors?.username}
            >
              <InputPassword
                placeholder="Mật khẩu"
                className="w-[342px] h-[52px] bg-[#f3f4f6] pl-[50px] border-0 rounded-[16px]"
                prefix={<IconPasswordStar className="w-[24px] h-[24px]" />}
                {...register('password')}
              />
            </FormItem>
            <Button
              type="primary"
              className="w-[124px] h-[52px] text-[12px] font-[700] bg-[#FAD209] rounded-[16px] shadow-xl"
              disabled={isLoading}
            >
              Đăng nhập
            </Button>
          </Form>
        </Row>
      </Row>
    </Spin>
  );
};

export default LoginPage;
