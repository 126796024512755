import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import TabForm from '_common/component/TabForm';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidateLength } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectTicketType from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  CubeOutlined,
  formatCurrency,
  Image,
  InformationCircleOutlined,
  ItemType,
  notification,
  Row,
  Spin,
  Tag,
} from 'tera-dls';
import OrderInspectionApi from '../../api';
import { ORDER_INSPECTION_URL } from '../../url';

const TABS_FORM = {
  information: {
    title: 'Thông tin chung',
    icon: <InformationCircleOutlined className="w-5 h-5" />,
  },
  product: {
    title: 'Sản phẩm',
    icon: <CubeOutlined className="w-5 h-5" />,
  },
};

function OrderInspectionForm() {
  const form = useForm<any>();
  const navigate = useNavigate();
  const { id } = useParams();
  const tableRef = useRef(null);

  const [dataSource, setDataSource] = useState([]);
  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['get-order-detail', id],
    () => OrderInspectionApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateOrder, isLoading: isLoadingMutate } = useMutation(
    (params: any) => OrderInspectionApi.check({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-order-inspection-list']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(ORDER_INSPECTION_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn kiểm hàng
        </a>
      ),
    },
    {
      title: 'Kiểm hàng',
    },
  ];

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => (
        <div className="flex gap-x-2.5">
          <Image
            src={record?.file_upload}
            alt={record?.file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
          />
          <p className="line-clamp-2 break-word">{name}</p>
        </div>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 150,
    },
    {
      title: 'Danh mục',
      dataIndex: 'warehouse_cata_log',
      width: 150,
      render: (warehouse_cata_log) => (
        <p className="line-clamp-2">{warehouse_cata_log}</p>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'product_unit_id',
      width: 100,
      render: (product_unit_id) => product_unit_id,
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'request_quantity',
      width: 100,
    },
    {
      title: 'Số lượng thiếu',
      dataIndex: 'insufficient_quantity',
      width: 150,
      editable: true,
      type: 'int',
      inputProps: (record) => {
        return {
          min: 0,
          max: record?.request_quantity - (record?.error_quantity || 0),
          formatter: (value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
        };
      },
    },
    {
      title: 'Số lượng lỗi',
      dataIndex: 'error_quantity',
      width: 150,
      editable: true,
      type: 'int',
      inputProps: (record) => {
        return {
          min: 0,
          max: record?.request_quantity - (record?.insufficient_quantity || 0),
          formatter: (value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
        };
      },
    },
    {
      title: 'Thực nhận',
      dataIndex: 'quantity',
      width: 100,
      render: (_, record) => {
        const quantity =
          record?.request_quantity -
          record?.error_quantity -
          record?.insufficient_quantity;
        return (
          <Tag
            color={record?.request_quantity === quantity ? 'green06' : 'red06'}
          >
            {quantity}
          </Tag>
        );
      },
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 200,
      render: (_, record) =>
        formatCurrency(
          (record?.request_quantity -
            record?.error_quantity -
            record?.insufficient_quantity) *
            record?.amount,
        ),
    },
  ];

  // convert data before call api
  const convertData = (data: any[]) => {
    const dataConverted = data.map((item) => ({
      id: item?.id,
      quantity_check:
        item?.request_quantity -
        item?.error_quantity -
        item?.insufficient_quantity,
      quantity_errors: item?.error_quantity,
      quantity_missing: item?.insufficient_quantity,
    }));
    return dataConverted;
  };

  // convert data after get api detail
  const convertDataTableProduct = (data: any[]) => {
    const products = data?.map((prod) => ({
      id: prod?.id,
      file_upload: prod?.image,
      name: prod?.product_name,
      product_unit_id: prod?.unit_name,
      abbreviation: prod?.abbreviation,
      amount: prod?.amount,
      warehouse_cata_log: prod?.catalog_name,

      request_quantity: prod?.quantity_approved || 0,
      error_quantity: prod?.quantity_errors || 0,
      insufficient_quantity: prod?.quantity_missing || 0,
      quantity: prod?.quantity_check || 0,
    }));
    return products;
  };

  const handleSubmitForm = () => {
    if (isLoadingMutate) return;
    const data = {
      order_id: id,
      order_detail: convertData(dataSource),
    };

    mutateOrder(data);
  };

  const total = useMemo(() => {
    const sum = dataSource?.reduce((total, current) => {
      return (
        total +
        (current?.request_quantity -
          current?.error_quantity -
          current?.insufficient_quantity) *
          current?.amount
      );
    }, 0);
    return sum;
  }, [dataSource]);

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        code: dataDetails?.code,
        ticket_type: dataDetails?.ticket_type,
        note: dataDetails?.note,
        invest: dataDetails?.invest_id?.name,
        type: dataDetails?.type,
        investor: dataDetails?.investor_name || 'Hệ thống',
        device: dataDetails?.device_name,
      };
      form.reset(initData);
      const products = convertDataTableProduct(
        dataDetails?.order_detail?.filter((order) => order?.quantity_approved),
      );
      setDataSource(products);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              disabled={isError || (isLoading && !!id) || isLoadingMutate}
            >
              Xác nhận
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        <div className="page-content-v2">
          {isError && !!id ? (
            <NoData />
          ) : (
            <TabForm menu={TABS_FORM}>
              <FormTera form={form}>
                <CardFormV2
                  title="Thông tin chung"
                  id="information"
                  className="form-section"
                >
                  <Row className="grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0">
                    <FormTeraItem label="Mã đơn hàng" name="code">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Đầu tư" name="invest">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Danh mục đầu tư" name="type">
                      <SelectInvestmentCategory disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Nhà đầu tư" name="investor">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Thiết bị" name="device">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Loại phiếu" name="ticket_type">
                      <SelectTicketType disabled />
                    </FormTeraItem>

                    <FormTeraItem
                      label="Ghi chú"
                      name="note"
                      className="col-span-3"
                      rules={[
                        {
                          maxLength: {
                            value: 1000,
                            message: messageValidateLength.textArea,
                          },
                        },
                      ]}
                    >
                      <TextArea rows={5} disabled />
                    </FormTeraItem>
                  </Row>
                </CardFormV2>
                <CardFormV2
                  title="Sản phẩm"
                  id="product"
                  className="form-section"
                >
                  <div className="overflow-hidden bg-white shadow-xsm rounded-[5px]">
                    <TableTera
                      actionRef={tableRef}
                      mode="editable-cell"
                      columns={columns}
                      data={dataSource}
                      pagination={{}}
                      editable={{
                        onValuesChange: (_, list) => {
                          const newList = list?.map((item) => ({
                            ...item,
                            total: item?.quantity * item?.amount,
                            insufficient_quantity:
                              item?.insufficient_quantity || 0,
                            error_quantity: item?.error_quantity || 0,
                          }));

                          setDataSource(newList);
                        },
                      }}
                    />
                    <div className="ml-auto max-w-max p-2.5 text-base">
                      <span className="text-gray-800">Tổng thành tiền: </span>
                      <span className="text-green-500 font-medium">
                        {formatCurrency(total)}
                      </span>
                    </div>
                  </div>
                </CardFormV2>
              </FormTera>
            </TabForm>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default OrderInspectionForm;
