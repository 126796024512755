import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  ColumnsType,
  formatCurrency,
  formatDate,
  Image,
  InformationCircleOutlined,
  ItemType,
  notification,
  PencilSquareOutlined,
  Row,
  Spin,
  Tag,
  Tooltip,
  XMarkOutlined,
} from 'tera-dls';
import OrderApi from '../../api';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { ORDER_URL } from '../../url';
import { usePermission } from '_common/hooks/usePermission';
import { ORDER_PERMISSION_KEY } from '_common/constants/permission';

function OrderDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-order-detail', id], () => OrderApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => OrderApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate, isLoading: loadingChangeStatus } = useMutation(
    (params: any) =>
      OrderApi.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-order-detail']);
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleReject = () => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn đặt hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối đơn đặt hàng</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id,
          params: {
            status: 'reject',
            type_status: 'sumi',
          },
        };
        mutate(params);
      },
    });
  };

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa đơn đặt hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn đặt hàng</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(ORDER_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn đặt hàng
        </a>
      ),
    },
    {
      title: 'Chi tiết đơn đặt hàng',
    },
  ];

  const detail = [
    {
      label: 'Đầu tư',
      value: dataDetails?.invest_name,
    },
    {
      label: 'Mã đơn hàng',
      value: dataDetails?.code,
    },
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.type],
    },
    {
      label: 'Loại phiếu',
      value: TICKET_TYPE[dataDetails?.ticket_type],
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Nhà đầu tư',
      value: dataDetails?.investor_name || 'Hệ thống',
    },
    {
      label: 'Ngày duyệt đơn hàng',
      value:
        dataDetails?.approved_date &&
        formatDate(dataDetails?.approved_date, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: 'Tên thiết bị đặt hàng',
      value: dataDetails?.device_name,
    },
    {
      label: 'Trạng thái',
      value: dataDetails?.order_status && (
        <div className="flex gap-x-2.5 items-center">
          <Tag color={ORDER_STATUS_COLOR[dataDetails?.order_status]}>
            {ORDER_STATUS[dataDetails?.order_status]}
          </Tag>
          {dataDetails?.type_status === 'sumi' && (
            <Tooltip
              title={`SUMI ${ORDER_STATUS[dataDetails?.order_status]}`}
              placement="top"
            >
              <div>
                <InformationCircleOutlined className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },

    {
      label: 'Ghi chú',
      value: dataDetails?.note,
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => (
        <div className="flex gap-x-2.5">
          <Image
            src={record?.file_upload}
            alt={record?.file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
          />
          <p className="line-clamp-2 break-word">{name}</p>
        </div>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 200,
    },
    {
      title: 'Danh mục',
      dataIndex: 'catalog_name',
      width: 200,
      render: (catalog_name) => <p className="line-clamp-2">{catalog_name}</p>,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_name',
      width: 100,
      render: (unit_name) => unit_name,
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'quantity_request',
      width: 100,
    },
    {
      title: 'Số lượng duyệt',
      dataIndex: 'quantity_approved',
      width: 100,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => formatCurrency(amount || 0),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (total) => formatCurrency(total || 0),
    },
    {
      title: 'Đánh giá - góp ý',
      dataIndex: 'feedback',
      width: 200,
    },
  ];

  const products = useMemo(() => {
    if (!dataDetails?.order_detail) return;
    const data = dataDetails?.order_detail?.map((prod) => ({
      name: prod?.product_name,
      catalog_name: prod?.catalog_name,
      unit_name: prod?.unit_name,
      amount: prod?.amount,
      file_upload: prod?.image,
      abbreviation: prod?.abbreviation,
      quantity_approved: prod?.quantity_approved,
      quantity_request: prod?.quantity_request,
      feedback: prod?.feedback,
      total:
        dataDetails?.order_status === 'approved'
          ? prod?.amount * prod?.quantity_approved
          : prod?.amount * prod?.quantity_request,
    }));
    return data;
  }, [dataDetails]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {dataDetails?.order_status === 'pending' &&
              hasPage(ORDER_PERMISSION_KEY.CHANGE_STATUS) && (
                <>
                  <Button
                    type="danger"
                    className="page-header-v2-btn"
                    onClick={handleReject}
                    disabled={isError || isLoading}
                  >
                    Từ chối
                  </Button>
                  <Button
                    type="success"
                    className="page-header-v2-btn"
                    onClick={() => navigate(`${ORDER_URL.approve.path}/${id}`)}
                    disabled={isError || isLoading}
                  >
                    Duyệt
                  </Button>
                </>
              )}
            {dataDetails?.order_status !== 'approved' && (
              <>
                {hasPage(ORDER_PERMISSION_KEY.UPDATE) && (
                  <Button
                    className="page-header-v2-btn"
                    prefix={<PencilSquareOutlined />}
                    onClick={() => navigate(`${ORDER_URL.update.path}/${id}`)}
                    disabled={isError || isLoading || loadingChangeStatus}
                  >
                    Sửa
                  </Button>
                )}
                {hasPage(ORDER_PERMISSION_KEY.DELETE) && (
                  <Button
                    className="page-header-v2-btn"
                    type="danger"
                    prefix={<XMarkOutlined />}
                    onClick={handleDelete}
                    disabled={isError || isLoading || loadingChangeStatus}
                  >
                    Xóa
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading || loadingDelete || loadingChangeStatus}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow">
            <div className="flex flex-col gap-4">
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin chung">
                  <Row className="grid grid-cols-3 gap-2.5">
                    {detail.map((item, index) => (
                      <div className="flex items-start" key={index}>
                        <div className="detail-key">{item.label}</div>
                        <div className="detail-value">{item.value}</div>
                      </div>
                    ))}
                  </Row>
                </CardFormV2>
              </div>
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Sản phẩm">
                  <TableTera
                    data={products}
                    columns={columns}
                    pagination={{}}
                  />
                  <div className="ml-auto max-w-max p-2.5 text-base">
                    <span className="text-gray-800">Tổng thành tiền: </span>
                    <span className="text-green-500 font-medium">
                      {formatCurrency(
                        dataDetails?.order_detail_total_price?.total_price,
                      )}
                    </span>
                  </div>
                </CardFormV2>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default OrderDetail;
