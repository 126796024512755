import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import TabForm from '_common/component/TabForm';
import { IconButton } from '_common/component/TableColumnCustom';
import ActionCUD from '_common/component/TableColumnCustom/ActionCUD';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_QUANTITY } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import ModalSelectProductInvestment from '_common/dof/Modal/ModalSelectProductInvestment';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectTicketType from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import InvestmentApi from 'pages/Invest/Invesment/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  CubeOutlined,
  formatCurrency,
  Image,
  InformationCircleOutlined,
  ItemType,
  notification,
  PlusCircleOutlined,
  Row,
  Spin,
} from 'tera-dls';
import OrderApi from '../../api';
import { ORDER_URL } from '../../url';

const TABS_FORM = {
  information: {
    title: 'Thông tin chung',
    icon: <InformationCircleOutlined className="w-5 h-5" />,
  },
  product: {
    title: 'Sản phẩm',
    icon: <CubeOutlined className="w-5 h-5" />,
  },
};

interface OrderFormProps {
  isApprove?: boolean;
}

function OrderForm({ isApprove }: OrderFormProps) {
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: { ticket_type: 'order' },
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const tableRef = useRef(null);

  const [openModalProduct, setOpenModalProduct] = useState({
    open: false,
    params: null,
  });
  const [dataSource, setDataSource] = useState([]);
  const queryClient = useQueryClient();
  const invest_id = form.watch('invest_id');

  const {
    data: dataDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-order-detail', id], () => OrderApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { data: dataDetailsInvest } = useQuery(
    ['get-investment-detail', invest_id],
    () => InvestmentApi.getDetail({ id: invest_id }),
    {
      enabled: !!invest_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateOrder, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (isApprove) return OrderApi.approval({ id, params });
      if (id) return OrderApi.update({ id, params });
      return OrderApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(ORDER_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn đặt hàng
        </a>
      ),
    },
    {
      title: isApprove
        ? 'Duyệt đơn đặt hàng'
        : !id
        ? 'Thêm đơn đặt hàng'
        : 'Sửa đơn đặt hàng',
    },
  ];

  const columns: any = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => (
        <div className="flex gap-x-2.5">
          <Image
            src={record?.file_upload}
            alt={record?.file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
          />
          <p className="line-clamp-2 break-word">{name}</p>
        </div>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 150,
    },
    {
      title: 'Danh mục',
      dataIndex: 'warehouse_cata_log',
      width: 150,
      render: (warehouse_cata_log) => (
        <p className="line-clamp-2">{warehouse_cata_log}</p>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'product_unit_id',
      width: 100,
      render: (product_unit_id) => product_unit_id,
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'quantity',
      width: 150,
      editable: !isApprove,
      type: 'int',
      inputProps: {
        min: 1,
        max: MAXIMUM_QUANTITY,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
      },
    },
    {
      title: 'Số lượng duyệt',
      dataIndex: 'quantity_approve',
      width: 150,
      editable: !!isApprove,
      type: 'int',
      inputProps: {
        min: 0,
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
      },
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => formatCurrency(amount || 0),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 150,
      render: (_, record) => {
        const total = isApprove
          ? Number(record?.amount * record?.quantity_approve)
          : Number(record?.amount * record?.quantity);
        return formatCurrency(total || 0);
      },
    },
    {
      title: 'Đánh giá - góp ý',
      dataIndex: 'feedback',
      width: 200,
      editable: !isApprove,
      rules: [
        {
          maxLength: {
            value: 1000,
            message: messageValidateLength.textLong,
          },
        },
      ],
    },
    ...(isApprove
      ? []
      : [
          {
            fixed: 'right',
            width: 60,
            render: (record) => (
              <ActionCUD
                onClickDelete={() =>
                  setDataSource((prev) => {
                    const newData = prev.filter(
                      (prod) => prod?.id !== record?.id,
                    );
                    return newData;
                  })
                }
              />
            ),
          },
        ]),
  ];

  // convert data before call api
  const convertData = (data: any[]) => {
    const dataConverted = data.map((item) => ({
      investment_product_id: item?.id,
      product_warehouse_id: item?.product_warehouse_id,
      abbreviation: item?.abbreviation,
      amount: item?.amount,
      quantity: item?.quantity,
      feedback: item?.feedback,

      product_name: item?.name,
      unit_name: item?.product_unit_id,
      catalog_name: item?.warehouse_cata_log,
      image: item?.file_upload,

      // approve
      quantity_approved: isApprove ? item?.quantity_approve : undefined,
    }));
    return dataConverted;
  };

  // convert data after get api detail
  const convertDataTableProduct = (data: any[]) => {
    const products = data?.map((prod) => ({
      id: prod?.investment_product_id,
      product_warehouse_id: prod?.product_warehouse_id,

      file_upload: prod?.image,
      name: prod?.product_name,
      product_unit_id: prod?.unit_name,
      abbreviation: prod?.abbreviation,
      warehouse_cata_log: prod?.catalog_name,

      amount: id ? prod?.amount : prod?.investment?.amount,
      quantity: prod?.quantity_request,
      quantity_approve: prod?.quantity_request,
      feedback: prod?.feedback,
    }));
    return products;
  };

  const convertDataAddProduct = (data: any[]) => {
    const products = data?.map((prod) => ({
      id: prod?.id,
      product_warehouse_id: prod?.product_warehouse?.id,
      file_upload: prod?.product_warehouse?.file_upload,
      name: prod?.product_warehouse?.name,
      product_unit_id: prod?.product_warehouse?.product_unit_id?.name,
      abbreviation: prod?.abbreviation,
      amount: prod?.amount,
      warehouse_cata_log: prod?.product_warehouse?.warehouse_cata_log?.name,
      quantity: 1,
      total: prod?.amount,
      feedback: null,
    }));
    return products;
  };

  const handleSubmitForm = (value) => {
    if (dataSource?.length <= 0) {
      notification.error({ message: 'Vui lòng chọn sản phẩm' });
      return;
    }
    if (isLoadingMutate) return;
    const data = {
      note: value?.note,
      invest_id: value?.invest_id,
      status: isApprove ? 'approved' : undefined,
      type_status: 'sumi',
      ticket_type: 'order',
      products: convertData(dataSource),
    };

    mutateOrder(data);
  };

  const total = useMemo(() => {
    const sum = dataSource?.reduce((total, current) => {
      return (
        total +
        (isApprove
          ? current?.quantity_approve * current?.amount
          : current?.quantity * current?.amount)
      );
    }, 0);
    return sum;
  }, [dataSource]);

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        invest_id: dataDetails?.invest_id?.id,
        note: dataDetails?.note,
        type: dataDetails?.type,
        investor: dataDetails?.investor_name || 'Hệ thống',
        device: dataDetails?.device_name,
        ticket_type: dataDetails?.ticket_type,
      };

      form.reset(initData);
      const products = convertDataTableProduct(dataDetails?.order_detail);
      setDataSource(products);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (dataDetailsInvest && !id) {
      form.setValue('type', dataDetailsInvest?.type);
      form.setValue(
        'investor',
        dataDetailsInvest?.investor_id
          ? dataDetailsInvest?.investor?.investor_name
          : 'Hệ thống',
      );
      form.setValue('device', dataDetailsInvest?.device?.full_name || '');
    }
  }, [dataDetailsInvest]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              disabled={isError || (isLoading && !!id) || isLoadingMutate}
            >
              {isApprove ? 'Duyệt' : 'Lưu'}
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        <div className="page-content-v2">
          {isError && !!id ? (
            <NoData />
          ) : (
            <TabForm menu={TABS_FORM}>
              <FormTera form={form}>
                <CardFormV2
                  title="Thông tin chung"
                  id="information"
                  className="form-section"
                >
                  <Row className="grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0">
                    <FormTeraItem
                      label="Đầu tư"
                      name="invest_id"
                      rules={[
                        {
                          required: messageValidate.emptySelect,
                        },
                      ]}
                    >
                      <SelectInvestment
                        allowClear={false}
                        paramsApi={{
                          include_id: invest_id,
                          isActive: true,
                        }}
                        onChangeCustom={() => setDataSource([])}
                        disabled={isApprove || !!id}
                      />
                    </FormTeraItem>

                    <FormTeraItem label="Danh mục đầu tư" name="type">
                      <SelectInvestmentCategory disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Nhà đầu tư" name="investor">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Thiết bị" name="device">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Loại phiếu" name="ticket_type">
                      <SelectTicketType disabled />
                    </FormTeraItem>

                    <FormTeraItem
                      label="Ghi chú"
                      name="note"
                      className="col-span-3"
                      rules={[
                        {
                          maxLength: {
                            value: 1000,
                            message: messageValidateLength.textArea,
                          },
                        },
                      ]}
                    >
                      <TextArea rows={5} disabled={isApprove} />
                    </FormTeraItem>
                  </Row>
                </CardFormV2>
                <CardFormV2
                  title="Sản phẩm"
                  id="product"
                  className="form-section"
                >
                  {!isApprove && (
                    <Button
                      htmlType="button"
                      className="mb-5 ml-auto"
                      prefix={<PlusCircleOutlined />}
                      onClick={() =>
                        setOpenModalProduct({ open: true, params: null })
                      }
                      disabled={!invest_id}
                    >
                      Thêm nhiều sản phẩm
                    </Button>
                  )}
                  <div className="overflow-hidden bg-white shadow-xsm rounded-[5px]">
                    <TableTera
                      hiddenColumns={!isApprove && ['quantity_approve']}
                      actionRef={tableRef}
                      mode="editable-cell"
                      columns={columns}
                      data={dataSource}
                      pagination={{}}
                      editable={{
                        onValuesChange: (_, list) => {
                          const newList = list
                            ?.filter((item) => !item?.isDelete)
                            ?.map((item) => ({
                              ...item,
                              total: item?.quantity * item?.amount,
                              quantity_approve: item?.quantity_approve || 0,
                            }));
                          setDataSource(newList);
                        },
                      }}
                    />
                    <div className="ml-auto max-w-max p-2.5 text-base">
                      <span className="text-gray-800">Tổng thành tiền: </span>
                      <span className="text-green-500 font-medium">
                        {formatCurrency(total)}
                      </span>
                    </div>
                  </div>
                </CardFormV2>
              </FormTera>
            </TabForm>
          )}
        </div>
      </Spin>
      {openModalProduct.open && (
        <ModalSelectProductInvestment
          paramsApi={{
            exclude_id: dataSource.map((data) => data?.id).join(','),
            invest_id: invest_id,
          }}
          open={openModalProduct.open}
          onCancel={() => setOpenModalProduct({ open: false, params: null })}
          onOk={(selectedRows) => {
            const products = convertDataAddProduct(selectedRows);
            setDataSource((prev) => [...prev, ...products]);
          }}
        />
      )}
    </div>
  );
}

export default OrderForm;
