import { IRouteProps } from '_common/interface/router';
import { ORDER_URL } from './url';
import Order from '.';
import OrderDetail from './containers/Detail';
import OrderForm from './containers/Form';
import RequestOrderDetail from './containers/Detail/RequestOrderDetail';

export const OrderRouter: IRouteProps[] = [
  {
    key: ORDER_URL.list.key,
    path: ORDER_URL.list.shortenUrl,
    component: <Order />,
  },
  {
    key: ORDER_URL.create.key,
    path: ORDER_URL.create.shortenUrl,
    component: <OrderForm />,
  },
  {
    key: ORDER_URL.update.key,
    path: ORDER_URL.update.shortenUrl,
    component: <OrderForm />,
  },
  {
    key: ORDER_URL.detail.key,
    path: ORDER_URL.detail.shortenUrl,
    component: <OrderDetail />,
  },
  {
    key: ORDER_URL.request_order_detail.key,
    path: ORDER_URL.request_order_detail.shortenUrl,
    component: <RequestOrderDetail />,
  },
  {
    key: ORDER_URL.approve.key,
    path: ORDER_URL.approve.shortenUrl,
    component: <OrderForm isApprove />,
  },
];
