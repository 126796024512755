import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatNumber,
  Image,
  notification,
  Tag,
} from 'tera-dls';
import ProductApi from '../../api';
import { PRODUCT_URL } from '../../url';
import ProductForm from '../Form';
import { usePermission } from '_common/hooks/usePermission';
import { PRODUCT_PERMISSION_KEY } from '_common/constants/permission';

function ProductTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => ProductApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-product-list']);
          queryClient.invalidateQueries(['get-summary-product-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => ProductApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-product-list']);
          queryClient.invalidateQueries(['get-summary-product-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangeStatus = (record) => {
    const text = record?.status == 1 ? 'ngưng' : 'mở';
    confirm.warning({
      title: `Xác nhận ${text} hoạt động sản phẩm`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text} hoạt động sản phẩm</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: record?.id,
          status: record?.status == 1 ? 0 : 1,
        };
        mutateChangeStatus(params);
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa sản phẩm',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa sản phẩm</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(PRODUCT_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id && navigate(`${PRODUCT_URL.detail.path}/${record?.id}`),
      });
    if (hasPage(PRODUCT_PERMISSION_KEY.CHANGE_STATUS))
      items.push({
        key: '2',
        label: record?.status == 1 ? 'Ngưng hoạt động' : 'Hoạt động',
        onClick: () => handleChangeStatus(record),
      });
    if (hasPage(PRODUCT_PERMISSION_KEY.UPDATE))
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(PRODUCT_PERMISSION_KEY.DELETE) && !record?.status)
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Hình ảnh',
      dataIndex: 'file_upload',
      width: 50,
      render: (file_upload) =>
        file_upload && (
          <Image
            src={file_upload}
            alt={file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border"
          />
        ),
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 300,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Danh mục',
      dataIndex: 'warehouse_cata_log',
      width: 200,
      render: (warehouse_cata_log) => (
        <p className="line-clamp-2">{warehouse_cata_log?.name}</p>
      ),
    },
    {
      title: 'Giới hạn số lượng đặt',
      dataIndex: 'quantity',
      width: 100,
      render: (quantity) => formatNumber(quantity),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'product_unit_id',
      width: 100,
      render: (product_unit_id) => product_unit_id?.name,
    },
    {
      title: 'Giá bán',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 50,
      fixed: 'right',
      dataIndex: 'action',
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || loadingChangeStatus || loadingDelete}
        {...props}
      />
      {openForm.open && (
        <ProductForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default ProductTable;
