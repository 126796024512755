import { SYSTEM_PERMISSION_KEY } from '_common/constants/permission';

export const PERMISSION_URL = {
  list: {
    key: SYSTEM_PERMISSION_KEY.VIEW_LIST,
    path: '/system/permission/list',
    shortenUrl: 'permission/list',
  },
  create: {
    key: SYSTEM_PERMISSION_KEY.CREATE,
    path: '/system/permission/create',
    shortenUrl: 'permission/create',
  },
  update: {
    key: SYSTEM_PERMISSION_KEY.UPDATE,
    path: '/system/permission/update',
    shortenUrl: 'permission/update/:id',
  },
  detail: {
    key: SYSTEM_PERMISSION_KEY.VIEW_DETAIL,
    path: '/system/permission/detail',
    shortenUrl: 'permission/detail/:id',
  },
};
