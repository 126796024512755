import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import TableTera from '_common/dof/TableTera';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  formatDate,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';

import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import useConfirm from '_common/hooks/useConfirm';
import TimekeepingApi from './api';
import TimekeepingDetail from './containers/Detail';
import TimekeepingFilter from './containers/Filter';
import TimekeepingForm from './containers/Form';
import TimekeepingSearch from './containers/Search';
import { TIMEKEEPING_URL } from './url';
import { TIME_FORMAT } from '_common/constants/common';
import { usePermission } from '_common/hooks/usePermission';
import { TIMEKEEPING_PERMISSION_KEY } from '_common/constants/permission';

function Timekeeping() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${TIMEKEEPING_URL.list.path}${paramString}`);
  };

  const {
    data: timekeepingList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-timekeeping-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return TimekeepingApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => TimekeepingApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-timekeeping-list']);
          queryClient.invalidateQueries(['get-summary-timekeeping-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(timekeepingList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa chấm công',
      content: 'Bạn có chắc muốn xóa chấm công này không?',
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(TIMEKEEPING_PERMISSION_KEY.SEARCH))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(TIMEKEEPING_PERMISSION_KEY.UPDATE))
      items.push({
        key: '2',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(TIMEKEEPING_PERMISSION_KEY.DELETE))
      items.push({
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: any = [
    {
      title: 'Đầu tư',
      dataIndex: 'invest',
      render: (invest) => <p className="line-clamp-2">{invest?.name}</p>,
    },
    {
      title: 'Ngày làm việc',
      dataIndex: 'working_date',
      render: (working_date) => formatDate(working_date),
    },
    {
      title: 'Thời gian vào ca',
      dataIndex: 'check_in_time',
      render: (check_in_time) => formatDate(check_in_time, TIME_FORMAT),
    },
    {
      title: 'Thời gian ra ca',
      dataIndex: 'check_out_time',
      render: (check_out_time) => formatDate(check_out_time, TIME_FORMAT),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      render: (description) => <p className="line-clamp-2">{description}</p>,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách chấm công"
        buttonFilterKey={TIMEKEEPING_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={TIMEKEEPING_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        actionLeftRender={
          hasPage(TIMEKEEPING_PERMISSION_KEY.SEARCH) && (
            <TimekeepingSearch onSearch={handleSearch} />
          )
        }
        onClickFilter={() => setIsOpenFilter(true)}
        filterCount={{
          filter: queryParams,
          params: ['date', 'check_in_time', 'check_out_time'],
        }}
      >
        <TableTera
          rowKey={'id'}
          columns={columns}
          data={timekeepingList?.data || [{ id: 1 }]}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: timekeepingList?.total || 0,
            current: timekeepingList?.current_page,
            pageSize: timekeepingList?.per_page || 10,
            to: timekeepingList?.to,
            from: timekeepingList?.from || 10,
          }}
        />
      </HeaderViewList>

      {openDetail.open && (
        <TimekeepingDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <TimekeepingForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-timekeeping-list']);
            queryClient.invalidateQueries(['get-summary-timekeeping-list']);
          }}
        />
      )}

      {isOpenFilter && (
        <TimekeepingFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
}

export default Timekeeping;
