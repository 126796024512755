import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT, TIME_FORMAT } from '_common/constants/common';
import { useEffect } from 'react';
import { formatDate, Image, Modal, Spin } from 'tera-dls';
import TimekeepingApi from '../../api';

interface TimekeepingDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function TimekeepingDetail({ id, open, onCancel }: TimekeepingDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-timekeeping-detail', id],
    () => TimekeepingApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const detail = [
    {
      label: 'Hình ảnh chấm công vào',
      value: dataDetails?.check_in_image && (
        <Image
          src={dataDetails?.check_in_image}
          alt={dataDetails?.check_in_image}
          containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden m-auto"
        />
      ),
    },
    {
      label: 'Hình ảnh chấm công ra',
      value: dataDetails?.check_out_image && (
        <Image
          src={dataDetails?.check_out_image}
          alt={dataDetails?.check_out_image}
          containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden m-auto"
        />
      ),
    },
    {
      label: 'Ngày làm việc',
      value: dataDetails?.working_date && formatDate(dataDetails?.working_date),
    },
    {
      label: 'Thời gian vào ca',
      value:
        dataDetails?.check_in_time &&
        formatDate(dataDetails?.check_in_time, TIME_FORMAT),
    },
    {
      label: 'Thời gian ra ca',
      value:
        dataDetails?.check_out_time &&
        formatDate(dataDetails?.check_out_time, TIME_FORMAT),
    },
    {
      label: 'Đầu tư',
      value: dataDetails?.invest?.name,
    },
    {
      label: 'Mô tả',
      value: dataDetails?.description,
    },
    {
      label: 'Ghi chú',
      value: dataDetails?.note,
    },
    {
      label: 'Người tạo',
      value: dataDetails?.created_by?.full_name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: dataDetails?.updated_by?.full_name,
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Modal
      title="Chi tiết chấm công"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-y-2.5">
            {detail.map((item, index) => (
              <div className="flex items-start" key={index}>
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default TimekeepingDetail;
