import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import {
  MAXIMUM_CURRENCY,
  MAXIMUM_QUANTITY,
  REGEX,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCategory from '_common/dof/Select/SelectCategory';
import SelectUnit from '_common/dof/Select/SelectUnit';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import ProductApi from '../../api';
import UploadFiles from '_common/dof/UploadFiles';
import TextArea from '_common/dof/Control/TextArea';

interface ProductFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function ProductForm(props: ProductFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      status: true,
      warehouse_catalog_id: null,
      files_upload: [],
    },
  });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();

  const [fileList, setFileList] = useState([]);

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-product-detail', id], () => ProductApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateProduct, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return ProductApi.update({ id, params });
      return ProductApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-product-list']);
          queryClient.invalidateQueries(['get-summary-product-list']);
          queryClient.invalidateQueries(['get-product-list-investment']); // update modal product investment
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...values,
      status: values?.status ? 1 : 0,
      file_upload:
        values?.file_upload ||
        'https://sumi-api.teravn.com/assets/upload/system/1723601523_default-image.png',
      files_upload: fileList?.map((file) => ({
        id: file?.table_id,
        file_id: file?.id,
        file_name: file?.name,
        file_path: file?.url,
        isDelete: file?.delete,
      })),
    };
    mutateProduct(data);
  };

  const handleReceiveFiles = (file) => {
    const value = {
      ...file,
      create: true,
    };
    setFileList((prev) => [value, ...prev]);
    form.setValue('files_upload', [value, ...fileList], {
      shouldDirty: true,
    });
  };

  const handleRemoveFile = (value: any) => {
    if (value?.create) {
      const files = fileList.filter((file) => file?.id !== value?.id);
      setFileList(files);
      return;
    }
    const image = {
      ...value,
      delete: true,
    };
    const files = fileList.map((file) => {
      if (file?.id === image?.id) return image;
      return file;
    });
    setFileList(files);
  };

  const filesRender = useMemo(() => {
    return fileList.filter((file) => !file?.delete);
  }, [fileList]);

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        file_upload: dataDetails?.file_upload,
        name: dataDetails?.name,
        abbreviation: dataDetails?.abbreviation,
        warehouse_catalog_id: dataDetails?.warehouse_catalog_id,
        quantity: dataDetails?.quantity,
        product_unit_id: dataDetails?.product_unit_id?.id,
        amount: dataDetails?.amount,
        status: dataDetails?.status == 1 ? true : false,
        note: dataDetails?.note,
      };

      const files = dataDetails?.files_upload?.map((file) => ({
        table_id: file?.id,
        id: file?.file_id,
        name: file?.file_name,
        url: file?.file_path,
      }));
      setFileList(files);

      form.reset({ ...initData });
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa sản phẩm' : 'Thêm sản phẩm'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[90%] xl:w-[1000px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form} className="grid grid-cols-2 gap-x-4">
            <div>
              <FormTeraItem
                label="Ảnh đại diện"
                className="flex flex-col col-span-2"
                name="file_upload"
                rules={[
                  {
                    required: messageValidate.emptySelect,
                  },
                ]}
              >
                <Image folder="product" object_key="product" />
              </FormTeraItem>
              <FormTeraItem
                label="Tên sản phẩm"
                name="name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 191,
                      message: messageValidateLength.text,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Tên viết tắt"
                name="abbreviation"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 15,
                      message: 'Nhập tối đa 15 kí tự',
                    },
                    pattern: {
                      value: new RegExp(REGEX.ABBREVIATION),
                      message: messageValidate.abbreviation,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Danh mục"
                name="warehouse_catalog_id"
                rules={[
                  {
                    required: messageValidate.emptySelect,
                  },
                ]}
              >
                <SelectCategory
                  paramsApi={{ include_id: form.watch('warehouse_catalog_id') }}
                />
              </FormTeraItem>
              <FormTeraItem label="Giới hạn số lượng đặt" name="quantity">
                <InputNumber min={0} max={MAXIMUM_QUANTITY} />
              </FormTeraItem>
              <FormTeraItem label="Đơn vị" name="product_unit_id">
                <SelectUnit />
              </FormTeraItem>
              <FormTeraItem label="Giá bán" name="amount">
                <InputNumber min={0} max={MAXIMUM_CURRENCY} />
              </FormTeraItem>
              <FormTeraItem label="Ghi chú" name="note">
                <TextArea rows={5} />
              </FormTeraItem>
              <FormTeraItem name="status" className="col-span-2">
                <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
              </FormTeraItem>
            </div>
            <div>
              <FormTeraItem name="images" className="col-span-2">
                <UploadFiles
                  max={50}
                  isCount={false}
                  folder="product_images"
                  object_key="product_images"
                  fileList={filesRender}
                  accept=".png, .jpeg, .gif, .jpg"
                  onReceiveFiles={handleReceiveFiles}
                  onRemove={handleRemoveFile}
                />
              </FormTeraItem>
            </div>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default ProductForm;
