import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import { AuthApi } from 'states/api';

import { useStores } from '_common/hooks';

import { Routers } from './routers';
import moment from 'moment';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';
moment.updateLocale('vn', {
  week: {
    dow: 1,
  },
});
const Root = observer(() => {
  const {
    authStore,
    commonStore: { setBusiness },
  } = useStores();

  useQuery(['get_device'], AuthApi.getDeviceCode, {
    staleTime: 300000,
    // enabled: !authStore.device,
    onSuccess: (data) => {
      authStore.setInitData(data);
    },
  });

  useQuery(['get_profile'], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      setBusiness({
        id: res?.data?.business?.id,
        name: res?.data?.business?.name,
        ownerName: res?.data?.business?.owner_name,
      });
      authStore.updateUser({ user: res?.data });
    },
  });

  // useQuery(['get_permissions'], AuthApi.getPermissions, {
  //   staleTime: 300000,
  //   enabled: !!authStore.token,
  //   onSuccess: (res) => {
  //     authStore.updatePermissions(res?.data);
  //   },
  // });
  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});

export default Root;
