import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  InformationCircleOutlined,
  notification,
  Tag,
  Tooltip,
} from 'tera-dls';
import IncurredCostApi from '../../api';
import {
  INCURRED_COST_STATUS,
  INCURRED_COST_STATUS_COLOR,
} from '../../constants';
import { INCURRED_COST_URL } from '../../url';
import IncurredCostForm from '../Form';
import { usePermission } from '_common/hooks/usePermission';
import { INCURRED_COST_PERMISSION_KEY } from '_common/constants/permission';

function IncurredCostTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => IncurredCostApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-incurred-cost-list']);
          queryClient.invalidateQueries(['get-summary-incurred-cost-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => IncurredCostApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-incurred-cost-list']);
          queryClient.invalidateQueries(['get-summary-incurred-cost-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangeStatus = (record, status: 'reject' | 'approved') => {
    const text = {
      reject: 'từ chối',
      approve: 'duyệt',
    };
    confirm.warning({
      title: `Xác nhận ${text[status]} chi phí phát sinh`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text[status]} chi phí phát sinh</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateChangeStatus({
          id: record?.id,
          status,
          type_status: 'sumi',
        }),
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa chi phí phát sinh',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa chi phí phát sinh</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(INCURRED_COST_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id &&
          navigate(`${INCURRED_COST_URL.detail.path}/${record?.id}`),
      });
    if (
      hasPage(INCURRED_COST_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.status === 'pending'
    )
      items.push({
        key: '2',
        label: 'Từ chối',
        onClick: () => handleChangeStatus(record, 'reject'),
      });
    if (
      hasPage(INCURRED_COST_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.status === 'pending'
    )
      items.push({
        key: '3',
        label: 'Duyệt',
        onClick: () => handleChangeStatus(record, 'approved'),
      });
    if (
      hasPage(INCURRED_COST_PERMISSION_KEY.UPDATE) &&
      record?.status !== 'approved'
    )
      items.push({
        key: '4',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (
      hasPage(INCURRED_COST_PERMISSION_KEY.DELETE) &&
      record?.status !== 'approved'
    )
      items.push({
        key: '5',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã CPPS',
      dataIndex: 'code',
      width: 100,
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_name',
      width: 200,
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_name',
      width: 200,
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      width: 150,
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      title: 'Tổng phát sinh',
      dataIndex: 'amount',
      width: 100,
      render: (amount) => formatCurrency(amount),
    },
    {
      title: 'Danh mục CPPS',
      dataIndex: 'incurred_cost_cate',
      width: 150,
      render: (incurred_cost_cate) => (
        <p className="line-clamp-2">{incurred_cost_cate?.name}</p>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status, record) =>
        status && (
          <div className="flex gap-x-2.5 items-center">
            <Tag color={INCURRED_COST_STATUS_COLOR[status]}>
              {INCURRED_COST_STATUS[status]}
            </Tag>
            {record?.type_status === 'sumi' && (
              <Tooltip
                title={`SUMI ${INCURRED_COST_STATUS[status]}`}
                placement="top"
              >
                <div>
                  <InformationCircleOutlined className="w-5 h-5" />
                </div>
              </Tooltip>
            )}
          </div>
        ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || loadingChangeStatus || loadingDelete}
        {...props}
      />
      {openForm.open && (
        <IncurredCostForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default IncurredCostTable;
