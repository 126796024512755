import { timeFormat } from '_common/constants/common';
import { IRuleValidation, IValidateReturn } from './_interfaces';
import moment from 'moment';

export function checkValidateForm(
  props: IRuleValidation,
  validateDefault: IValidateReturn,
): IValidateReturn {
  const validate = validateDefault || {};

  if (props?.min?.value || props?.max?.value) {
    validate['isNumber'] = (value) => !isNaN(value) || 'Nhập một số hợp lệ';
  }
  if (props?.maxDate) {
    validate['maxDate'] = (currentValue, state) => {
      if (
        moment(currentValue, timeFormat.date_time)?.isAfter(
          moment(state?.[props?.maxDate?.key], timeFormat.date_time),
          'minutes',
        )
      ) {
        return props?.maxDate?.message;
      }

      return true;
    };
  }
  if (props?.minDate) {
    validate['minDate'] = (currentValue, state) => {
      if (
        moment(currentValue, timeFormat.date_time)?.isBefore(
          moment(state?.[props?.minDate?.key], timeFormat.date_time),
          'minutes',
        )
      ) {
        return props?.minDate?.message;
      }
      return true;
    };
  }

  if (props?.pattern) {
    validate['isRegex'] = (currentValue) => {
      if (currentValue && !props?.pattern.value.test(currentValue)) {
        return props?.pattern?.message;
      }
      return true;
    };
  }

  if (props?.required) {
    validate['required'] = (currentValue) => {
      const trimmedData = currentValue ?? '';
      if (typeof trimmedData === 'string' && trimmedData.trim() === '') {
        return props?.pattern?.message ?? (props?.required as string);
      }
      return true;
    };
  }

  if (props?.maxNumber) {
    validate['maxNumber'] = (currentValue, state) => {
      if (
        currentValue == undefined ||
        state?.[props?.maxNumber?.key] == undefined
      )
        return true;

      const { comparisonType = 'greater_than' } = props?.maxNumber;

      if (comparisonType === 'greater_than') {
        if (Number(currentValue) > Number(state?.[props?.maxNumber?.key]))
          return props?.maxNumber?.message;
      } else {
        if (Number(currentValue) >= Number(state?.[props?.maxNumber?.key]))
          return props?.maxNumber?.message;
      }

      return true;
    };
  }
  if (props?.minNumber) {
    validate['minNumber'] = (currentValue, state) => {
      if (
        currentValue == undefined ||
        state?.[props?.minNumber?.key] == undefined
      )
        return true;

      const { comparisonType = 'less_than' } = props?.minNumber;

      if (comparisonType === 'less_than') {
        if (Number(currentValue) < Number(state?.[props?.minNumber?.key]))
          return props?.minNumber?.message;
      } else {
        if (Number(currentValue) <= Number(state?.[props?.minNumber?.key]))
          return props?.minNumber?.message;
      }

      return true;
    };
  }
  if (props?.lessThan) {
    validate['lessThan'] = (currentValue, state) => {
      if (
        currentValue == undefined ||
        state?.[props?.lessThan?.key] == undefined
      )
        return true;
      const { isEqual = true } = props?.lessThan;

      if (!isEqual && currentValue >= state?.[props?.lessThan?.key])
        return props?.lessThan?.message;

      if (currentValue > state?.[props?.lessThan?.key])
        return props?.lessThan?.message;

      return true;
    };
  }
  if (props?.greaterThan) {
    validate['greaterThan'] = (currentValue, state) => {
      if (
        currentValue == undefined ||
        state?.[props?.greaterThan?.key] == undefined
      )
        return true;
      const { isEqual = true } = props?.greaterThan;

      if (!isEqual && currentValue <= state?.[props?.greaterThan?.key])
        return props?.greaterThan?.message;

      if (currentValue < state?.[props?.greaterThan?.key])
        return props?.greaterThan?.message;

      return true;
    };
  }
  return validate;
}
